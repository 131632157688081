import http from '../http-engine';

export default {
  getLanguages(params) {
    return http.get('/api/v1/entryI18nLanguages', { params });
  },
  createLanguage(params) {
    return http.post('/api/v1/entryI18nLanguages', params);
  },
  updateLanguage(params) {
    const param = {
      fields: ['name'],
      data: params,
    };
    return http.patch(`/api/v1/entryI18nLanguages/${params.id}`, param);
  },
  deleteLanguage(params) {
    return http.delete(`/api/v1/entryI18nLanguages/${params}`);
  },
};
