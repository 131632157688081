import http from '../http-engine';

export default {
  getDistrict(params) {
    return http.get('/api/v1/district/rank/1', { params });
  },
  getCity(params) {
    return http.get(`/api/v1/district/city/${params}`);
  },
};
