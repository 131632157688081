/* eslint-disable */
export default {
  parseTime(time, cFormat) {
    if (!time) {
      return '';
    }
    if (arguments.length === 0) {
      return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
      date = time;
    } else {
      if ((`${time}`).length === 10) time = parseInt(time) * 1000;
      date = new Date(time);
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    };
    const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key];
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value]; }
      if (result.length > 0 && value < 10) {
        value = `0${value}`;
      }
      return value || 0;
    });
    return timeStr;
  },
  statusFilter(status) {
    const map = {
      1: '正常',
      2: '冻结',
      3: '删除',
    };
    return map[status];
  },
  orNA(text, defaultValue) {
    if (!defaultValue) {
      defaultValue = "/";
    }
    return text ? text : defaultValue;
  },
}
