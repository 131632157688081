const errorMessage = {
  ERROR_VERIFICATION_CODE: '验证码错误',
  NO_SUCH_USER: '用户名不存在',
  SYS_REQUIRED: '必填参数缺失',
  SYS_INTERNAL_ERROR_OCCURS: '系统微服务错误',
  SYS_INVALID_ARGUMENT: '参数有误',
  NOT_SAME_PROJECT: '当前中控并不属于此项目',
  NO_BINDING_PERMISSION: '用户权限不足',
  VERSION_TOO_LOW: '当前应用版本太低，请升级应用',
  PROJECT_IS_DELIVERED: '项目已交付',
  CONTAIN_NOT_CONFIGURED_HOUSEHOLD: '包含未配置的住宅',
  PROJECT_NOT_DELIVERED: '项目未交付',
  SYS_DUPLICATED: '已存在，不需要重复添加',
  FETCH_REQUEST_LIMIT: '短时间内操作过于频繁',
  FW_UNSUPPORTED_FIRMWARE_TYPE: '该固件型号尚不支持',
  FW_INVALID_FIRMWARE_TYPE: '该固件类型错误',
  FW_INVALID_FIRMWARE_VERSION: '该固件版本错误',
  FW_INVALID_FIRMWARE_MD5: '文件损坏',
  NOT_NEED_TO_BIND: '不需要绑定该用户',
  SYS_AUTH_UNAUTHORIZED: '当前用户无权限',
  FOREIGN_KEY_CONSTRAINT: '数据被引用不可删除',
};

export default {
  getErrorMessage(error) {
    const errors = error;
    if (errorMessage[error.errorCode]) {
      errors.message = errorMessage[error.errorCode];
    }
    return errors;
  },
};
