const columns = [
  {
    title: '产品目录ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: '产品目录名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    key: 'creator',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    align: 'center',
  },
  {
    title: '上次修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  columns,
};
