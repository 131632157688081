const FILTER_OPTION_DEVICETYPE_NAME = {
  value: '0',
  label: '设备类型',
};
const FILTER_OPTION_DEVICETYPE_ENNAME = {
  value: '1',
  label: '英文名',
};
const countryColumns = [
  {
    width: '30%',
    title: '语言',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
  },
  {
    title: '设备类型名称',
    dataIndex: 'nameMessage',
    scopedSlots: { customRender: 'nameMessage' },
    ellipsis: true,
  },
  {
    width: '20%',
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const columns = [
  {
    title: '设备类型id',
    dataIndex: 'deviceTypeId',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' },
    align: 'center',
  },
  {
    title: '设备类型',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '负载设备类型',
    dataIndex: 'loadedDeviceTypeName',
    align: 'center',
  },
  {
    title: '英文名',
    dataIndex: 'enName',
    align: 'center',
  },
  // {
  //   title: '备注说明',
  //   dataIndex: 'remark',
  //   key: 'remark',
  //   align: 'center',
  // },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const DEVICE_TYPE = {
  机顶盒: '1',
  空调: '5',
};
export default {
  DEVICE_TYPE,
  FILTER_OPTION_DEVICETYPE_NAME,
  FILTER_OPTION_DEVICETYPE_ENNAME,
  columns,
  countryColumns,
};
