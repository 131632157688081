import http from '../http-engine';

export default {
  login(params) {
    return http.post('/api/v1/management/login', params);
  },
  refresh() {
    return http.post('/api/v1/refresh-auth');
  },
};
