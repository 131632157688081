import http from '../http-engine';

export default {
  getCpuSoftwareList(payload) {
    return http.get(`/api/v1/cpuModels/${payload.model}/softVersions`, { params: payload });
  },
  addCpuSoftware(payload) {
    return http.post(`/api/v1/cpuModels/${payload.model}/softVersions`, payload);
  },
  getCpuSoftware(payload) {
    return http.get(`/api/v1/cpuModels/${payload.model}/softVersions/${payload.softVersion}`);
  },
  delCpuSoftware(payload) {
    return http.delete(`/api/v1/cpuModels/${payload.model}/softVersions/${payload.softVersion}`);
  },
  updateCpuSoftware(payload) {
    return http.patch(`/api/v1/cpuModels/${payload.data.model}/softVersions/${payload.data.softVersion}`, payload);
  },
};
