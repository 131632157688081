const type = {
  int32: 0,
  float: 1,
  boolean: 2,
  string: 3,
  rgb: 4,
  strings: 5,
  time: 6,
  long: 7,
  rgbs: 8,
  file: 9,
};

const editMode = {
  // 输入型
  input: 0,
  // 选项型
  select: 1,
  // 固定值
  fixed: 2,
};
const editModeText = {
  // 输入型
  0: '输入型',
  // 选项型
  1: '选项型',
  // 固定值
  2: '固定值',
};
export default {
  type,
  editMode,
  editModeText,
};
