import http from '../http-engine';

export default {
  getHolidays(params) {
    return http.get('/api/v1/dateinformation', { params });
  },
  deleteHolidays(params) {
    return http.delete(`/api/v1/dateinformation/${params}`);
  },
  initHolidays(params) {
    return http.post('/api/v1/dateinformation:initialize', params);
  },

  updateHolidays(param) {
    const params = {
      fields: 'dateType',
    };
    return http.patch(`/api/v1/dateinformation/${param.date}`, param, { params });
  },
};
