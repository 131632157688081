import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';

// import utils from '../common/utils';
import store from '../core/store';
import app from '../main';
import eventBus from '../core/event-bus';
// import user from '../data/const/user';
import errorMessage from '../data/const/error-message';

const SYS_AUTHORIZATION_TOKEN_EXPIRED = 'SYS_AUTHORIZATION_TOKEN_EXPIRED';
const SYS_AUTHENTICATION_ERROR = 'SYS_AUTHENTICATION_ERROR';
const SYS_AUTHORIZATION_INVALID_USER = 'SYS_AUTHORIZATION_INVALID_USER';
const SYS_AUTH_TOKEN_EXPIRED = 'SYS_AUTH_TOKEN_EXPIRED';
// const SYS_AUTH_UNAUTHORIZED = 'SYS_AUTH_UNAUTHORIZED';
const SYS_AUTH_INVALID_TOKEN = 'SYS_AUTH_INVALID_TOKEN';
const SYS_AUTH_UNAUTHENTICATED = 'SYS_AUTH_UNAUTHENTICATED';

const engine = axios.create({
  baseURL: '/',
  timeout: 6000000,
  headers: {
    'Content-Type': 'application/json',
    'NEXHOME-APP-ID': 'rcs-management-ui',
    'accept-language': 'zh-CN',
  },
  paramsSerializer: (data) => qs.stringify(data, { indices: false }),
});
function processRequestParams(requestConfig) {
  lodash.forIn(requestConfig.params, (value, key) => {
    if (value == null || value === '') {
      lodash.unset(requestConfig.params, key);
    }
  });
  lodash.forIn(requestConfig.data, (value, key) => {
    if (value == null || value === '') {
      lodash.unset(requestConfig.data, key);
    }
  });
}

/**
 * 请求拦截器，处理请求头信息
 */
engine.interceptors.request.use((config) => {
  // 匿名接口去除Authorization
  const apiWhiteList = ['/api/v1/publicProducts', '/api/v1/publicLoadedDeviceTypes', '/api/v1/metadata'];
  const custom = config;
  processRequestParams(custom);

  if (store.state.currentUser && store.state.currentUser.accessToken) {
    custom.headers.Authorization = store.state.currentUser.accessToken;
  }
  apiWhiteList.forEach((api) => {
    if (custom.url.includes(api)) {
      delete custom.headers.Authorization;
    }
  });
  store.commit('startRequest', config.url);
  return custom;
});

/**
 * 响应拦截器，处理状态码和异常
 */
const whileList = /api\/v1\/cpuModels\/.+\/featureRelations:getByVersion/;
engine.interceptors.response.use(
  (response) => {
    store.commit('endRequest', response.config.url);
    const res = response.data;
    /* 当响应的业务码无误时抛回前端 */
    if (res.data && (!res.errors || res.errors.length === 0)) {
      return res;
    }
    if (whileList.test(response.config.url)) {
      return res;
    }
    if (res.errors) {
      res.errors.forEach((error) => {
        switch (error.errorCode) {
          case SYS_AUTHORIZATION_TOKEN_EXPIRED:
          case SYS_AUTHENTICATION_ERROR:
          case SYS_AUTHORIZATION_INVALID_USER:
          case SYS_AUTH_TOKEN_EXPIRED:
          case SYS_AUTH_INVALID_TOKEN:
          case SYS_AUTH_UNAUTHENTICATED:
            eventBus.$emit('logout');
            break;
          default:
            break;
        }
        Object.assign(error, errorMessage.getErrorMessage(error));
        app.$notification.error({
          message: '发现错误',
          description: `${error.errorCode}:${error.message}`,
        });
      });
    }
    /* 当响应的业务码有误时作为错误处理 */
    return Promise.reject(res.errors);
  },
  (error) => {
    /* 非请求异常时清空请求标示 */
    if (error.config) {
      store.commit('endRequest', error.config.url);
    } else {
      store.commit('endRequest', '', true);
    }
    /* 当请求失败时，构建一个错误体丢回前端 */
    const errorRes = {
      errors: [
        {
          fieldPath: '',
          value: '',
          errorCode: '0',
          message: 'Request Failed',
        },
      ],
    };
    app.$notification.error({
      message: '发现错误',
      description: errorRes.errors[0].message,
    });
    return Promise.reject(errorRes);
  },
);

export default engine;
