const columns = [
  {
    title: '配置项标识符',
    dataIndex: 'identifier',
    key: 'identifier',
    align: 'center',
  },
  {
    title: '配置项定义唯一标识',
    dataIndex: 'encode',
    key: 'encode',
    align: 'center',
  },
  {
    title: '配置项描述',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
  },
  {
    title: '配置项备注内容',
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const editColumns = [
  {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
  },
  {
    title: 'description',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
  },

  {
    title: 'originalFilename',
    dataIndex: 'originalFilename',
    key: 'originalFilename',
    align: 'center',
    scopedSlots: { customRender: 'originalFilename' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const ITEM_TYPE = {
  DYNAMIC_DEPOT: 1,
};
export default {
  columns,
  editColumns,
  ITEM_TYPE,
};
