<template>
  <a-drawer width="60%" :visible="visible" :title="role.name" :maskClosable="false" @close="handleClose">
    <a-row style="margin-bottom: 1rem">
      <a-col :span="6" :offset="18">
        <a-input-search v-model="searchValue" placeholder="请输入模块名称" @search="handleSearch"> </a-input-search>
      </a-col>
    </a-row>
    <a-table class="permission-table" style="margin-bottom: 3rem;" :dataSource="tableData" :pagination="false" row-key="module">
      <a-table-column data-index="moduleName" title="模块" key="moduleName">
        <template slot-scope="scope">
          <a-checkbox :checked="moduleIsChecked(scope)" @change="(e)=>handleModuleChange(scope,e.target.checked)">
            {{ scope }}
          </a-checkbox>
        </template>
      </a-table-column>
      <a-table-column data-index="permissions" title="权限" key="permissions">
        <template slot-scope="scope">
          <a-row :gutter="[24, 16]">
            <a-col :span="permissionCol(scope.length)" v-for="permission in scope" :key="permission.code">
              <a-checkbox
                :checked="isChecked(permission.code)"
                @change="(e) => handlePermissionChange([permission.code], e.target.checked)"
              >
                {{ permission.actionName }}
              </a-checkbox>
            </a-col>
          </a-row>
        </template>
      </a-table-column>
    </a-table>
    <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 8px" @click="handleClose">
          取消
        </a-button>
        <a-button :loading="confirmLoading" type="primary" @click="handleSubmit">
          确认
        </a-button>
      </div>
  </a-drawer>
</template>

<script>
import lodash from 'lodash';
// eslint-disable-next-line import/extensions
import { Permissions } from '../../../service/permissions.js';

export default {
  name: 'RolePermissionDrawer',
  computed: {
    permissionCol() {
      return (permissionLength) => {
        if (permissionLength >= 4) {
          return 6;
        }
          return 24 / permissionLength;
      };
    },
  },
  data() {
    return {
      visible: false,
      searchValue: undefined,
      role: {
        id: '',
        name: '',
        permissions: [],
        remark: '',
      },
      tableData: [],
      originTableData: [],
      callback: undefined,
      confirmLoading: false,
    };
  },
  methods: {
    showPermissionDrawer(role, callback) {
      this.role = lodash.cloneDeep(role);
      this.callback = callback;
      this.visible = true;
      this.getPermissionTableData();
    },
    handleClose() {
      this.visible = false;
      this.searchValue = undefined;
      this.role = {
        id: '',
        name: '',
        permissions: [],
        remark: '',
      };
    },
    getPermissionTableData() {
      // 根据resourceCode聚合对应模块的权限
      const modelPermissions = {};
      Object.keys(Permissions).forEach((key) => {
        const permissionObj = Permissions[key];
        if (modelPermissions[permissionObj.resourceCode]) {
          modelPermissions[permissionObj.resourceCode].push(permissionObj);
        } else {
          modelPermissions[permissionObj.resourceCode] = [permissionObj];
        }
      });
      const permissionTableData = Object.keys(modelPermissions).map((module) => ({
        module,
        moduleName: modelPermissions[module][0].resourceName,
        permissions: modelPermissions[module],
      }));
      this.tableData = permissionTableData;
      this.originTableData = lodash.cloneDeep(permissionTableData);
    },
    async handlePermissionChange(codes, checked) {
      if (checked) {
        this.role.permissions = lodash.uniq([...this.role.permissions, ...codes]);
      } else {
        this.role.permissions = this.role.permissions.filter((permission) => !codes.includes(permission));
      }
    },
    handleModuleChange(moduleName, checked) {
      const modulePermissions = this.originTableData.find((item) => item.moduleName === moduleName).permissions.map((item) => item.code);
      this.handlePermissionChange(modulePermissions, checked);
    },
    isChecked(code) {
      return !!this.role.permissions.includes(code);
    },
    moduleIsChecked(moduleName) {
      const modulePermissions = this.originTableData.find((item) => item.moduleName === moduleName).permissions.map((item) => item.code);
      return lodash.isEqual(
        lodash.sortBy(lodash.intersection(this.role.permissions, modulePermissions)),
        lodash.sortBy(modulePermissions),
      );
    },
    handleSearch() {
      if (this.searchValue) {
        this.tableData = lodash.cloneDeep(
          this.originTableData.filter((item) => item.moduleName.includes(this.searchValue)),
        );
      } else {
        this.tableData = lodash.cloneDeep(this.originTableData);
      }
    },
    async handleSubmit() {
      this.confirmLoading = true;
      try {
        await this.callback(this.role);
        this.handleClose();
      } finally {
        this.confirmLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>

</style>
