import http from '../http-engine';

export default {
  getProductConfigurationDLLFiles(params) {
    return http.get(`/api/v1/productConfigurationItems/${params.configurationItemId}/dllFiles`, { params });
  },
  getHardVersionDLLFiles(params) {
    return http.get(`/api/v1/cpuModels/${params.model}/hardVersions/${params.hardVersion}/dllFiles`, {
      params: {
        configurationItemId: params.configurationItemId,
        productId: params.productId,
      },
    });
  },
  bindDLLFiles(params) {
    return http.post('/api/v1/dllFiles', params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  updateDLLFiles(params) {
    return http.put(`/api/v1/dllFiles/${params.id}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  deleteDLLFiles(id) {
    return http.delete(`/api/v1/dllFiles/${id}`);
  },
  getDLLFile(params) {
    return http.get('/api/v1/dllFiles:get', { params });
  },
};
