import navRouters from './models/nav-router';
import errorRouters from './models/error';
import productRouters from './models/aihome-router';
import BaseRouterNavItem from './base/base-nav-router-item';
import ModelRouters from './models/model-router';
import Main from '../page/MainPage';

const subsystemRouters = [
  {
    name: 'irRemote',
    alias: '/',
    path: '/irRemote',
    component: () => import('../layout/Index'),
    meta: {
      title: '红外码库管理平台',
      navBarVisible: true,
      roles: [],
      permissionCodeList: [
        'rcs:service_provider:list',
        'rcs:brand:list',
        'rcs:device_type:list',
        'rcs:remote_controller:detail',
      ],
    },
    children: navRouters,
    redirect: '/irRemote/serviceProviderList',
  },
  {
    name: 'aiHome',
    // alias: '/aiHome',
    path: '/aiHome',
    component: () => import('../layout/Index'),
    meta: {
      title: 'AIHome管理平台',
      navBarVisible: true,
      permissionCodeList: [
        'rcs:region:list',
        'rcs:product_category:list',
        'rcs:product_sub_class:list',
        'rcs:loaded_device_type:list',
        'rcs:product:list',
        'rcs:i18n:list',
        'rcs:entry_i18n_language:list',
        'rcs:entry_i18n_entity:list',
        'rcs:entry_i18n:list',
        'rcs:product_property:list',
        'rcs:product_service:list',
        'rcs:product_configuration:list',
        'rcs:date_information:list',
        'rcs:cpu_model:list',
        'rcs:cpu_hard_version:list',
        'rcs:cpu_soft_version:list',
        'rcs:cpu_feature:list',
        'rcs:cpu_feature_relation:list',
        'rcs:scene_type:list',
        'rcs:platform:list',
      ],
    },
    children: productRouters,
    redirect: '/aiHome/regionManagement',
  },
];

const routers = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [...subsystemRouters, ...errorRouters],
  },
  new BaseRouterNavItem('/login', 'Login', '登录', () => import('../page/login/Login'), {}),
  {
    path: '/model',
    name: 'model',
    component: () => import('../page/physicalModel/PhysicalModel'),
    children: ModelRouters,
    redirect: '/model/ModelProductList',
    meta: {
      requireLogin: false,
    },
  },
];

export { routers, subsystemRouters };
