import http from '../http-engine';

export default {
  getProductSubClasses(params, headers) {
    return http.get(
      `/api/v1/productCategories/${params.productCategoryId ? params.productCategoryId : '-'}/productClasses/${
        params.productClassId ? params.productClassId : '-'
      }/productSubClasses`,
      { params, headers },
    );
  },
  getProductSubClassById(params, headers) {
    return http.get(`/api/v1/productCategories/-/productClasses/-/productSubClasses/${params}`, { headers });
  },
  createProductSubClass(params) {
    return http.post(`/api/v1/productCategories/-/productClasses/${params.productClassId}/productSubClasses`, params);
  },
  updateProductSubClass(params) {
    const param = {
      fields: [
        'name',
        'kind',
        'productClassId',
        'type',
        'solidImages',
        'lineImages',
        'renderedImages',
        'thumbnails',
        'distributionNetworkUrl',
        'remark',
        'productId',
        'regionIdentifiers',
        'recommendedLoadedDeviceTypes',
      ],
      data: params,
    };
    return http.patch(`/api/v1/productCategories/-/productClasses/-/productSubClasses/${params.id}`, param);
  },
  deleteProductSubClass(params) {
    return http.delete(`/api/v1/productCategories/-/productClasses/-/productSubClasses/${params}`);
  },
  updateSort(params) {
    return http.post('/api/v1/productCategories/-/productClasses/-/productSubClasses:updateSort', params);
  },
};
