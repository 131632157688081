import http from '../http-engine';

export default {
  getI18nEntities(params) {
    return http.get('/api/v1/entryI18nEntities', { params });
  },
  createI18nEntity(params) {
    return http.post('/api/v1/entryI18nEntities', params);
  },
  updateI18nEntity(params) {
    const param = {
      fields: ['name'],
      data: params,
    };
    return http.patch(`/api/v1/entryI18nEntities/${params.id}`, param);
  },
  deleteI18nEntity(params) {
    return http.delete(`/api/v1/entryI18nEntities/${params}`);
  },
};
