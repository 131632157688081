const Module = {
  productSubClass: 1,
  loadedDeviceType: 2,
  product: 3,
  productConfigurationItem: 4,
};

const Submodule = {
  solidImages: 1,
  lineImages: 2,
  renderedImages: 3,
  thumbnails: 4,
  iconImage: 5,
  file: 6,
};

export default {
  Module,
  Submodule,
};
