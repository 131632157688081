import BaseRouterNavItem from '../base/base-nav-router-item';

const modelRouters = [
  new BaseRouterNavItem(
    'ModelProductList',
    'ModelProductList',
    '产品列表',
    () => import('../../page/physicalModel/ProductList'),
    {
      navBarVisible: true,
      requireLogin: false,
    },
  ),
  new BaseRouterNavItem(
    'ModelLoadDeviceList',
    'ModelLoadDeviceList',
    '负载类型列表',
    () => import('../../page/physicalModel/LoadDeviceList'),
    {
      navBarVisible: true,
      requireLogin: false,
    },
  ),
  new BaseRouterNavItem(
    'ModelProductList/modelProductDetail/:id',
    'modelProductDetail',
    '产品(物模型)详情',
    () => import('../../page/physicalModel/ProductDetail'),
    { navBarVisible: false, requireLogin: false },
  ),
  new BaseRouterNavItem(
    'ModelLoadDeviceList/modelLoadedDeviceDetail/:id',
    'modelLoadedDeviceDetail',
    '负载类型详情',
    () => import('../../page/physicalModel/LoadDeviceDetail'),
    { navBarVisible: false, requireLogin: false },
  ),
];

export default modelRouters;
