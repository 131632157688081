import http from '../http-engine';

export default {
  getProductService(params) {
    return http.get('/api/v1/productServiceItems', { params });
  },
  createProductService(params) {
    return http.post('/api/v1/productServiceItems', params);
  },
  updateProductService(params) {
    const param = {
      fields: ['timeout', 'description', 'parameters', 'remark'],
      data: params,
    };
    return http.patch(`/api/v1/productServiceItems/${params.id}`, param);
  },
  deleteProductService(params) {
    return http.delete(`/api/v1/productServiceItems/${params}`);
  },
  getproductServiceById(id, headers) {
    return http.get(`/api/v1/productServiceItems/${id}:batchGet`, { headers });
  },
};
