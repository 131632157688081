const userRoles = {
  admin: 0,
  platformManager: 1,
  afterSales: 2,
  worker: 3,
  contractors: 4,
};

const allUserRoles = {
  0: '系统管理员',
  1: '平台管理员',
  2: '售后人员',
  3: '施工人员',
};

const allUserStatus = {
  0: '正常',
  1: '停用',
};

export default {
  /** All roles definitions used for role exporting. */
  roles: userRoles,

  /**
   * Gets the role name acording to the user role id.
   *
   * @param role the user role id or {@code null} for non exist role.
   */
  getRoleName(role) {
    return allUserRoles[role];
  },

  /**
   * Gets the status name acording to the user status.
   *

   * * @param status the user status name or {@code null} for invalid status code.
   */
  getStatusName(status) {
    return allUserStatus[status];
  },
};
