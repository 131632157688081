import BaseRouterNavItem from '../base/base-nav-router-item';

const errorRouters = [
  new BaseRouterNavItem(
    '/error',
    'error',
    '异常页面',
    () => import('../../layout/Index'),
    {
      navBarVisible: false,
    },
    [
      new BaseRouterNavItem('/error/error403', 'error403', '403', () => import('../../page/error/ErrorPage403'), {
        navBarVisible: false,
      }),
      new BaseRouterNavItem('/error/error404', 'error404', '404', () => import('../../page/error/ErrorPage404'), {
        navBarVisible: false,
      }),
    ],
  ),
];

export default errorRouters;
