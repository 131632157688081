import http from '../http-engine';

export default {
  getCpuFunctionList(payload) {
    return http.get('/api/v1/cpuFeatures', { params: payload });
  },
  addCpuFunction(payload) {
    return http.post('/api/v1/cpuFeatures', payload);
  },
  getCpuFunction(feature) {
    return http.get(`/api/v1/cpuFeatures/${feature}`);
  },
  delCpuFunction(feature) {
    return http.delete(`/api/v1/cpuFeatures/${feature}`);
  },
  updateCpuFunction(payload) {
    return http.patch(`/api/v1/cpuFeatures/${payload.data.feature}`, payload);
  },
};
