import BaseRouterNavItem from '../base/base-nav-router-item';

const navRouters = [
  new BaseRouterNavItem(
    'serviceProviderList',
    'serviceProviderList',
    '服务商管理',
    () => import('../../page/ir-remote/service-provider/ServiceProviderList'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:service_provider:list'],
    },
  ),
  new BaseRouterNavItem(
    'serviceProviderCreator',
    'serviceProviderCreator',
    '服务商添加',
    () => import('../../page/ir-remote/service-provider/ServiceProviderCreator'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'serviceProviderEdit',
    'serviceProviderEdit',
    '服务商编辑',
    () => import('../../page/ir-remote/service-provider/ServiceProviderEdit'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'serviceProviderI18n',
    'serviceProviderI18n',
    '服务商国际化',
    () => import('../../page/ir-remote/service-provider/ServiceProviderI18n'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem('brandList', 'brandList', '品牌管理', () => import('../../page/ir-remote/brand/BrandList'), {
    navBarVisible: true,
    permissionCodeList: ['rcs:brand:list'],
  }),
  new BaseRouterNavItem(
    'brandCreator',
    'brandCreator',
    '品牌添加',
    () => import('../../page/ir-remote/brand/BrandCreator'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem('brandI18n', 'brandI18n', '品牌国际化', () => import('../../page/ir-remote/brand/BrandI18n'), {
    navBarVisible: false,
  }),
  new BaseRouterNavItem('brandEdit', 'brandEdit', '品牌编辑', () => import('../../page/ir-remote/brand/BrandEdit'), {
    navBarVisible: false,
  }),
  new BaseRouterNavItem(
    'deviceTypeList',
    'deviceTypeList',
    '设备类型管理',
    () => import('../../page/ir-remote/device-type/DeviceTypeList'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:device_type:list'],
    },
  ),
  new BaseRouterNavItem(
    'deviceTypeCreator',
    'deviceTypeCreator',
    '设备类型添加',
    () => import('../../page/ir-remote/device-type/DeviceTypeCreator'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'deviceTypeI18n',
    'deviceTypeI18n',
    '设备类型国际化',
    () => import('../../page/ir-remote/device-type/DeviceTypeI18n'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'deviceTypeEdit',
    'deviceTypeEdit',
    '设备类型编辑',
    () => import('../../page/ir-remote/device-type/DeviceTypeEdit'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'templateEdit',
    'templateEdit',
    '模板编辑',
    () => import('../../page/ir-remote/template/TemplateEdit'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'remoteControllerList',
    'remoteControllerList',
    '码库管理',
    () => import('../../page/ir-remote/remote-controllers/RemoteControllerList'),
    { navBarVisible: true, permissionCodeList: ['rcs:remote_controller:detail'] },
  ),
  new BaseRouterNavItem(
    'remoteControllerCreator',
    'remoteControllerCreator',
    '码库添加',
    () => import('../../page/ir-remote/remote-controllers/RemoteControllerCreator'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'remoteControllerI18n',
    'remoteControllerI18n',
    '码库国际化',
    () => import('../../page/ir-remote/remote-controllers/RemoteControllerI18n'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'remoteControllerEdit',
    'remoteControllerEdit',
    '码库编辑',
    () => import('../../page/ir-remote/remote-controllers/RemoteControllerEdit'),
    { navBarVisible: false },
  ),
];

export default navRouters;
