const columns = [
  {
    title: '平台ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: '平台名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',

  },
  {
    title: '平台私钥键',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    width: 200,
  },
  {
    title: '平台私钥值',
    key: 'secret',
    align: 'center',
    scopedSlots: { customRender: 'secret' },
    width: 200,
  },
  {
    title: '发布区域',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
    scopedSlots: { customRender: 'region' },

  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    align: 'center',
    scopedSlots: { customRender: 'createdTime' },
  },
  {
    title: '最后修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    align: 'center',
    scopedSlots: { customRender: 'lastModificationTime' },

  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    scopedSlots: { customRender: 'operation' },

  },
];

export default {
  columns,
};
