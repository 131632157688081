import http from '../http-engine';

export default {
  getRemoteController(params) {
    return http.get('/api/v1/remoteControllers/rel', { params });
  },
  getRemoteControllerDetail(params) {
    return http.get(`/api/v1/remoteControllers/rel/${params}`);
  },
  createRemoteController(params) {
    return http.post('/api/v1/remoteControllers/', params);
  },
  updateRemoteController(params) {
    return http.put('/api/v1/remoteControllers/', params);
  },
  deleteRemoteController(params) {
    return http.delete(`/api/v1/remoteControllers/${params}`);
  },
  deleteSpRemoteRels(params) {
    return http.delete(`/api/v1/spRemoteRels/${params}`);
  },
  deleteBrandRemoteRels(params) {
    return http.delete(`/api/v1/brandRemoteRels/${params}`);
  },
  createBrandRemoteRel(params) {
    return http.post('/api/v1/brandRemoteRels/', params);
  },
  createSpRemoteRels(params) {
    return http.post('/api/v1/spRemoteRels/batch', params);
  },
  createBrandRemoteRels(params) {
    return http.post('/api/v1/brandRemoteRels/batch', params);
  },
};
