<template>
  <a-modal
    :confirmLoading="confirmLoading"
    :visible="visible"
    :title="isUpdate ? '编辑角色' : '新增角色'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form ref="form" :model="role">
      <a-form-item label="角色编码" prop="encode" required>
        <a-input v-model="role.encode" :disabled="isUpdate" placeholder="请输入角色编码" allowClear></a-input>
      </a-form-item>
      <a-form-item label="角色名称" prop="name" required>
        <a-input v-model="role.name" placeholder="请输入角色名称" allowClear></a-input>
      </a-form-item>
      <a-form-item label="角色备注" prop="remark">
        <a-textarea v-model="role.remark" placeholder="请输入角色备注" allowClear />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'RoleFormModal',
  data() {
    return {
      visible: false,
      isUpdate: false,
      role: {
        name: '',
        remark: '',
        encode: '',
      },
      callback: undefined,
      confirmLoading: false,
    };
  },
  methods: {
    async handleOk() {
      if (!this.role.encode) {
        this.$message.warn('请填写角色编码');
        return;
      }
      if (!this.role.name) {
        this.$message.warn('请填写角色名称');
        return;
      }
      this.confirmLoading = true;
      try {
        await this.callback(this.role);
        this.handleCancel();
      } finally {
        this.confirmLoading = false;
      }
    },
    handleCancel() {
      this.visible = false;
      this.isUpdate = false;
      this.role = {
        name: '',
        remark: '',
        encode: '',
      };
      this.callback = undefined;
    },
    showCreateModal(cb) {
      this.visible = true;
      this.callback = cb;
      this.isUpdate = false;
    },
    showUpdateModal(role, cb) {
      this.callback = cb;
      this.role = lodash.cloneDeep(role);
      this.visible = true;
      this.isUpdate = true;
    },
  },
};
</script>
