const FilterOptions = [
  {
    value: 'identifierKey',
    label: '产品型号',
  },
  {
    value: 'nameKey',
    label: '产品名称',
  },
];

export default {
  FilterOptions,
};
