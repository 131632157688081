const columns = [
  {
    title: '服务项标识符',
    dataIndex: 'identifier',
    key: 'identifier',
    align: 'center',
    width: '15%',
  },
  {
    title: '服务描述',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
    width: '20%',
  },
  {
    title: '超时时间',
    dataIndex: 'timeout',
    key: 'timeout',
    scopedSlots: { customRender: 'timeout' },
    align: 'center',
    width: '15%',
  },
  {
    title: '服务项备注',
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
    width: '30%',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: '20%',
  },
];
export default {
  columns,
};
