const FILTER_OPTION_BRAND_NAME = {
  value: '0',
  label: '品牌名称',
};
const FILTER_OPTION_BRAND_ENNAME = {
  value: '1',
  label: '英文名',
};
const countryColumns = [
  {
    width: '30%',
    title: '语言',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
  },
  {
    title: '品牌名称',
    dataIndex: 'nameMessage',
    scopedSlots: { customRender: 'nameMessage' },
    ellipsis: true,
  },
  {
    width: '20%',
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const columns = [
  {
    title: '品牌id',
    dataIndex: 'brandId',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' },
    align: 'center',
  },
  {
    title: '品牌名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '英文名',
    dataIndex: 'enName',
    align: 'center',
  },
  // {
  //   title: '备注说明',
  //   dataIndex: 'remark',
  //   key: 'remark',
  //   align: 'center',
  // },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  FILTER_OPTION_BRAND_NAME,
  FILTER_OPTION_BRAND_ENNAME,
  columns,
  countryColumns,
};
