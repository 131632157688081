// Code generated by protoc-gen-ts-apis. DO NOT EDIT.
// versions:
// - protoc-gen-ts-apis v1.0.0
// - protoc             v5.26.1
// - nexhomeapis        cda7539b1e0791ae800aff049bc48f8b7a858265

export const Permissions = {

RCS_BRAND_UPDATE: {
  code: 'rcs:brand:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand',
  resourceName: '品牌管理',
  actionCode: 'update',
  actionName: '更新品牌',
},

RCS_BRAND_CREATE: {
  code: 'rcs:brand:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand',
  resourceName: '品牌管理',
  actionCode: 'create',
  actionName: '创建品牌',
},

RCS_BRAND_DELETE: {
  code: 'rcs:brand:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand',
  resourceName: '品牌管理',
  actionCode: 'delete',
  actionName: '删除品牌',
},

RCS_BRAND_LIST: {
  code: 'rcs:brand:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand',
  resourceName: '品牌管理',
  actionCode: 'list',
  actionName: '获取品牌列表',
},

RCS_BRAND_REMOTE_REL_UPDATE: {
  code: 'rcs:brand_remote_rel:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand_remote_rel',
  resourceName: '品牌遥控关系管理',
  actionCode: 'update',
  actionName: '更新品牌遥控关系',
},

RCS_BRAND_REMOTE_REL_CREATE: {
  code: 'rcs:brand_remote_rel:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand_remote_rel',
  resourceName: '品牌遥控关系管理',
  actionCode: 'create',
  actionName: '创建品牌遥控关系',
},

RCS_BRAND_REMOTE_REL_DELETE: {
  code: 'rcs:brand_remote_rel:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand_remote_rel',
  resourceName: '品牌遥控关系管理',
  actionCode: 'delete',
  actionName: '删除品牌遥控关系',
},

RCS_BRAND_REMOTE_REL_LIST: {
  code: 'rcs:brand_remote_rel:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'brand_remote_rel',
  resourceName: '品牌遥控关系管理',
  actionCode: 'list',
  actionName: '获取品牌遥控关系列表',
},

RCS_CPU_FEATURE_DELETE: {
  code: 'rcs:cpu_feature:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature',
  resourceName: '中控中控功能管理',
  actionCode: 'delete',
  actionName: '删除功能',
},

RCS_CPU_FEATURE_UPDATE: {
  code: 'rcs:cpu_feature:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature',
  resourceName: '中控中控功能管理',
  actionCode: 'update',
  actionName: '更新功能',
},

RCS_CPU_FEATURE_CREATE: {
  code: 'rcs:cpu_feature:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature',
  resourceName: '中控中控功能管理',
  actionCode: 'create',
  actionName: '创建功能',
},

RCS_CPU_FEATURE_GET: {
  code: 'rcs:cpu_feature:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature',
  resourceName: '中控中控功能管理',
  actionCode: 'get',
  actionName: '查看单个功能详情',
},

RCS_CPU_FEATURE_LIST: {
  code: 'rcs:cpu_feature:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature',
  resourceName: '中控中控功能管理',
  actionCode: 'list',
  actionName: '获取功能列表',
},

RCS_CPU_FEATURE_RELATION_UPDATE: {
  code: 'rcs:cpu_feature_relation:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature_relation',
  resourceName: '中控功能关联',
  actionCode: 'update',
  actionName: '更新功能关联',
},

RCS_CPU_FEATURE_RELATION_CREATE: {
  code: 'rcs:cpu_feature_relation:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature_relation',
  resourceName: '中控功能关联',
  actionCode: 'create',
  actionName: '创建功能关联',
},

RCS_CPU_FEATURE_RELATION_GET: {
  code: 'rcs:cpu_feature_relation:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature_relation',
  resourceName: '中控功能关联',
  actionCode: 'get',
  actionName: '查看单个功能详情关联',
},

RCS_CPU_FEATURE_RELATION_LIST: {
  code: 'rcs:cpu_feature_relation:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature_relation',
  resourceName: '中控功能关联',
  actionCode: 'list',
  actionName: '获取功能列表关联',
},

RCS_CPU_FEATURE_RELATION_DELETE: {
  code: 'rcs:cpu_feature_relation:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature_relation',
  resourceName: '中控功能关联',
  actionCode: 'delete',
  actionName: '删除单个功能关联',
},

RCS_CPU_FEATURE_RELATION_PUBLISH: {
  code: 'rcs:cpu_feature_relation:publish',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_feature_relation',
  resourceName: '中控功能关联',
  actionCode: 'publish',
  actionName: '发布功能',
},

RCS_CPU_HARD_VERSION_DELETE: {
  code: 'rcs:cpu_hard_version:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_hard_version',
  resourceName: '中控硬件版本管理',
  actionCode: 'delete',
  actionName: '删除硬件版本',
},

RCS_CPU_HARD_VERSION_UPDATE: {
  code: 'rcs:cpu_hard_version:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_hard_version',
  resourceName: '中控硬件版本管理',
  actionCode: 'update',
  actionName: '更新硬件版本',
},

RCS_CPU_HARD_VERSION_CREATE: {
  code: 'rcs:cpu_hard_version:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_hard_version',
  resourceName: '中控硬件版本管理',
  actionCode: 'create',
  actionName: '创建硬件版本',
},

RCS_CPU_HARD_VERSION_GET: {
  code: 'rcs:cpu_hard_version:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_hard_version',
  resourceName: '中控硬件版本管理',
  actionCode: 'get',
  actionName: '查看单个硬件版本详情',
},

RCS_CPU_HARD_VERSION_LIST: {
  code: 'rcs:cpu_hard_version:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_hard_version',
  resourceName: '中控硬件版本管理',
  actionCode: 'list',
  actionName: '获取硬件版本列表',
},

RCS_CPU_MODEL_DELETE: {
  code: 'rcs:cpu_model:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_model',
  resourceName: '中控型号管理',
  actionCode: 'delete',
  actionName: '删除型号',
},

RCS_CPU_MODEL_UPDATE: {
  code: 'rcs:cpu_model:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_model',
  resourceName: '中控型号管理',
  actionCode: 'update',
  actionName: '更新型号',
},

RCS_CPU_MODEL_CREATE: {
  code: 'rcs:cpu_model:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_model',
  resourceName: '中控型号管理',
  actionCode: 'create',
  actionName: '创建型号',
},

RCS_CPU_MODEL_GET: {
  code: 'rcs:cpu_model:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_model',
  resourceName: '中控型号管理',
  actionCode: 'get',
  actionName: '查看单个型号详情',
},

RCS_CPU_MODEL_LIST: {
  code: 'rcs:cpu_model:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_model',
  resourceName: '中控型号管理',
  actionCode: 'list',
  actionName: '获取型号列表',
},

RCS_CPU_SOFT_VERSION_DELETE: {
  code: 'rcs:cpu_soft_version:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_soft_version',
  resourceName: '中控软件版本管理',
  actionCode: 'delete',
  actionName: '删除软件版本',
},

RCS_CPU_SOFT_VERSION_UPDATE: {
  code: 'rcs:cpu_soft_version:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_soft_version',
  resourceName: '中控软件版本管理',
  actionCode: 'update',
  actionName: '更新软件版本',
},

RCS_CPU_SOFT_VERSION_CREATE: {
  code: 'rcs:cpu_soft_version:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_soft_version',
  resourceName: '中控软件版本管理',
  actionCode: 'create',
  actionName: '创建软件版本',
},

RCS_CPU_SOFT_VERSION_GET: {
  code: 'rcs:cpu_soft_version:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_soft_version',
  resourceName: '中控软件版本管理',
  actionCode: 'get',
  actionName: '查看单个软件版本详情',
},

RCS_CPU_SOFT_VERSION_LIST: {
  code: 'rcs:cpu_soft_version:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'cpu_soft_version',
  resourceName: '中控软件版本管理',
  actionCode: 'list',
  actionName: '获取软件版本列表',
},

RCS_DATE_INFORMATION_UPDATE: {
  code: 'rcs:date_information:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'date_information',
  resourceName: '日期信息管理',
  actionCode: 'update',
  actionName: '更新日期信息',
},

RCS_DATE_INFORMATION_CREATE: {
  code: 'rcs:date_information:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'date_information',
  resourceName: '日期信息管理',
  actionCode: 'create',
  actionName: '创建日期信息',
},

RCS_DATE_INFORMATION_DELETE: {
  code: 'rcs:date_information:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'date_information',
  resourceName: '日期信息管理',
  actionCode: 'delete',
  actionName: '删除日期信息',
},

RCS_DATE_INFORMATION_LIST: {
  code: 'rcs:date_information:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'date_information',
  resourceName: '日期信息管理',
  actionCode: 'list',
  actionName: '获取日期信息列表',
},

RCS_DEVICE_TYPE_UPDATE: {
  code: 'rcs:device_type:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'device_type',
  resourceName: '设备类型管理',
  actionCode: 'update',
  actionName: '更新设备类型',
},

RCS_DEVICE_TYPE_CREATE: {
  code: 'rcs:device_type:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'device_type',
  resourceName: '设备类型管理',
  actionCode: 'create',
  actionName: '创建设备类型',
},

RCS_DEVICE_TYPE_DELETE: {
  code: 'rcs:device_type:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'device_type',
  resourceName: '设备类型管理',
  actionCode: 'delete',
  actionName: '删除设备类型',
},

RCS_DEVICE_TYPE_LIST: {
  code: 'rcs:device_type:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'device_type',
  resourceName: '设备类型管理',
  actionCode: 'list',
  actionName: '获取设备类型列表',
},

RCS_DISTRICT_GET: {
  code: 'rcs:district:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'district',
  resourceName: '地理信息管理',
  actionCode: 'get',
  actionName: '查看地理信息详情',
},

RCS_DISTRICT_LIST: {
  code: 'rcs:district:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'district',
  resourceName: '地理信息管理',
  actionCode: 'list',
  actionName: '获取地理信息列表',
},

RCS_DLL_FILE_UPDATE: {
  code: 'rcs:dll_file:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'dll_file',
  resourceName: '动态库文件管理',
  actionCode: 'update',
  actionName: '修改动态库文件',
},

RCS_DLL_FILE_CREATE: {
  code: 'rcs:dll_file:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'dll_file',
  resourceName: '动态库文件管理',
  actionCode: 'create',
  actionName: '绑定动态库文件',
},

RCS_DLL_FILE_GET: {
  code: 'rcs:dll_file:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'dll_file',
  resourceName: '动态库文件管理',
  actionCode: 'get',
  actionName: '查询动态库文件详情',
},

RCS_DLL_FILE_LIST: {
  code: 'rcs:dll_file:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'dll_file',
  resourceName: '动态库文件管理',
  actionCode: 'list',
  actionName: '批量获取动态库文件信息',
},

RCS_DLL_FILE_DELETE: {
  code: 'rcs:dll_file:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'dll_file',
  resourceName: '动态库文件管理',
  actionCode: 'delete',
  actionName: '删除动态库文件',
},

RCS_ENTITY_LANGUAGE_LIST: {
  code: 'rcs:entity_language:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entity_language',
  resourceName: '词条实体和语言关系管理',
  actionCode: 'list',
  actionName: '获取词条实体和语言关系列表',
},

RCS_ENTITY_LANGUAGE_CREATE: {
  code: 'rcs:entity_language:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entity_language',
  resourceName: '词条实体和语言关系管理',
  actionCode: 'create',
  actionName: '创建词条实体和语言关系',
},

RCS_ENTITY_LANGUAGE_DELETE: {
  code: 'rcs:entity_language:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entity_language',
  resourceName: '词条实体和语言关系管理',
  actionCode: 'delete',
  actionName: '删除词条实体和语言关系',
},

RCS_ENTRY_I18N_UPDATE: {
  code: 'rcs:entry_i18n:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n',
  resourceName: '词条管理',
  actionCode: 'update',
  actionName: '更新词条信息',
},

RCS_ENTRY_I18N_LIST: {
  code: 'rcs:entry_i18n:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n',
  resourceName: '词条管理',
  actionCode: 'list',
  actionName: '获取词条列表',
},

RCS_ENTRY_I18N_ENTITY_UPDATE: {
  code: 'rcs:entry_i18n_entity:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_entity',
  resourceName: '词条实体管理',
  actionCode: 'update',
  actionName: '更新词条实体',
},

RCS_ENTRY_I18N_ENTITY_CREATE: {
  code: 'rcs:entry_i18n_entity:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_entity',
  resourceName: '词条实体管理',
  actionCode: 'create',
  actionName: '创建词条实体',
},

RCS_ENTRY_I18N_ENTITY_DELETE: {
  code: 'rcs:entry_i18n_entity:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_entity',
  resourceName: '词条实体管理',
  actionCode: 'delete',
  actionName: '删除词条实体',
},

RCS_ENTRY_I18N_ENTITY_LIST: {
  code: 'rcs:entry_i18n_entity:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_entity',
  resourceName: '词条实体管理',
  actionCode: 'list',
  actionName: '获取词条实体列表',
},

RCS_ENTRY_I18N_LANGUAGE_UPDATE: {
  code: 'rcs:entry_i18n_language:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_language',
  resourceName: '词条语言管理',
  actionCode: 'update',
  actionName: '更新词条语言',
},

RCS_ENTRY_I18N_LANGUAGE_CREATE: {
  code: 'rcs:entry_i18n_language:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_language',
  resourceName: '词条语言管理',
  actionCode: 'create',
  actionName: '创建词条语言',
},

RCS_ENTRY_I18N_LANGUAGE_DELETE: {
  code: 'rcs:entry_i18n_language:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_language',
  resourceName: '词条语言管理',
  actionCode: 'delete',
  actionName: '删除词条语言',
},

RCS_ENTRY_I18N_LANGUAGE_LIST: {
  code: 'rcs:entry_i18n_language:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'entry_i18n_language',
  resourceName: '词条语言管理',
  actionCode: 'list',
  actionName: '获取词条语言列表',
},

RCS_FILE_UPLOAD: {
  code: 'rcs:file:upload',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'file',
  resourceName: '文件管理',
  actionCode: 'upload',
  actionName: '文件上传',
},

RCS_I18N_CREATE: {
  code: 'rcs:i18n:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'i18n',
  resourceName: '国际化管理',
  actionCode: 'create',
  actionName: '创建国际化',
},

RCS_I18N_LIST: {
  code: 'rcs:i18n:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'i18n',
  resourceName: '国际化管理',
  actionCode: 'list',
  actionName: '获取国际化列表',
},

RCS_INFRARED_TEMPLATE_GET: {
  code: 'rcs:infrared_template:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'infrared_template',
  resourceName: '红外模版管理',
  actionCode: 'get',
  actionName: '查看红外模版详情',
},

RCS_INFRARED_TEMPLATE_LIST: {
  code: 'rcs:infrared_template:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'infrared_template',
  resourceName: '红外模版管理',
  actionCode: 'list',
  actionName: '获取红外模版列表',
},

RCS_IR_KEY_LIST: {
  code: 'rcs:ir_key:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'ir_key',
  resourceName: '红外按键管理',
  actionCode: 'list',
  actionName: '获取红外按键列表',
},

RCS_LDAP_USER_UPDATE: {
  code: 'rcs:ldap_user:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'ldap_user',
  resourceName: 'ldap用户管理',
  actionCode: 'update',
  actionName: '更新ldap用户',
},

RCS_LDAP_USER_GET: {
  code: 'rcs:ldap_user:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'ldap_user',
  resourceName: 'ldap用户管理',
  actionCode: 'get',
  actionName: '查看ldap用户详情',
},

RCS_LDAP_USER_LIST: {
  code: 'rcs:ldap_user:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'ldap_user',
  resourceName: 'ldap用户管理',
  actionCode: 'list',
  actionName: '获取ldap用户列表',
},

RCS_LOADED_DEVICE_TYPE_UPDATE: {
  code: 'rcs:loaded_device_type:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'loaded_device_type',
  resourceName: '负载设备类型管理',
  actionCode: 'update',
  actionName: '更新负载设备类型',
},

RCS_LOADED_DEVICE_TYPE_GET: {
  code: 'rcs:loaded_device_type:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'loaded_device_type',
  resourceName: '负载设备类型管理',
  actionCode: 'get',
  actionName: '查看负载设备类型详情',
},

RCS_LOADED_DEVICE_TYPE_LIST: {
  code: 'rcs:loaded_device_type:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'loaded_device_type',
  resourceName: '负载设备类型管理',
  actionCode: 'list',
  actionName: '获取负载设备类型列表',
},

RCS_LOADED_DEVICE_TYPE_CREATE: {
  code: 'rcs:loaded_device_type:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'loaded_device_type',
  resourceName: '负载设备类型管理',
  actionCode: 'create',
  actionName: '创建负载设备类型',
},

RCS_LOADED_DEVICE_TYPE_DELETE: {
  code: 'rcs:loaded_device_type:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'loaded_device_type',
  resourceName: '负载设备类型管理',
  actionCode: 'delete',
  actionName: '删除负载设备类型',
},

RCS_LOADED_DEVICE_TYPE_LIST_FROM_CACHE: {
  code: 'rcs:loaded_device_type:list_from_cache',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'loaded_device_type',
  resourceName: '负载设备类型管理',
  actionCode: 'list_from_cache',
  actionName: 'Iot平台刷新负载设备类型缓存',
},

RCS_PLATFORM_UPDATE: {
  code: 'rcs:platform:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'update',
  actionName: '修改单个平台',
},

RCS_PLATFORM_CREATE: {
  code: 'rcs:platform:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'create',
  actionName: '创建平台',
},

RCS_PLATFORM_GET: {
  code: 'rcs:platform:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'get',
  actionName: '获取单个平台',
},

RCS_PLATFORM_LIST: {
  code: 'rcs:platform:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'list',
  actionName: '获取平台列表',
},

RCS_PLATFORM_DELETE: {
  code: 'rcs:platform:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'delete',
  actionName: '删除平台',
},

RCS_PLATFORM_SECRET: {
  code: 'rcs:platform:secret',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'secret',
  actionName: '获取平台私钥的值',
},

RCS_PLATFORM_REFRESH: {
  code: 'rcs:platform:refresh',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'platform',
  resourceName: '平台管理',
  actionCode: 'refresh',
  actionName: '刷新平台私钥的值',
},

RCS_PRODUCT_UPDATE: {
  code: 'rcs:product:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product',
  resourceName: '物模型管理',
  actionCode: 'update',
  actionName: '更新物模型',
},

RCS_PRODUCT_CREATE: {
  code: 'rcs:product:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product',
  resourceName: '物模型管理',
  actionCode: 'create',
  actionName: '创建物模型',
},

RCS_PRODUCT_GET: {
  code: 'rcs:product:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product',
  resourceName: '物模型管理',
  actionCode: 'get',
  actionName: '查看物模型详情',
},

RCS_PRODUCT_LIST: {
  code: 'rcs:product:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product',
  resourceName: '物模型管理',
  actionCode: 'list',
  actionName: '获取物模型列表',
},

RCS_PRODUCT_LOADED_DEVICE_TYPE_ID_UPDATE: {
  code: 'rcs:product_loaded_device_type_id:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_loaded_device_type_id',
  resourceName: '物模型中绑定的负载设备Id管理',
  actionCode: 'update',
  actionName: '更新物模型中绑定的负载设备Id',
},

RCS_PRODUCT_CATEGORY_UPDATE: {
  code: 'rcs:product_category:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_category',
  resourceName: '产品目录管理',
  actionCode: 'update',
  actionName: '更新产品目录',
},

RCS_PRODUCT_CATEGORY_GET: {
  code: 'rcs:product_category:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_category',
  resourceName: '产品目录管理',
  actionCode: 'get',
  actionName: '查看产品目录详情',
},

RCS_PRODUCT_CATEGORY_LIST: {
  code: 'rcs:product_category:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_category',
  resourceName: '产品目录管理',
  actionCode: 'list',
  actionName: '获取产品目录列表',
},

RCS_PRODUCT_CATEGORY_CREATE: {
  code: 'rcs:product_category:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_category',
  resourceName: '产品目录管理',
  actionCode: 'create',
  actionName: '创建产品目录',
},

RCS_PRODUCT_CATEGORY_DELETE: {
  code: 'rcs:product_category:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_category',
  resourceName: '产品目录管理',
  actionCode: 'delete',
  actionName: '删除产品目录',
},

RCS_PRODUCT_CLASS_UPDATE: {
  code: 'rcs:product_class:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_class',
  resourceName: '产品大类管理',
  actionCode: 'update',
  actionName: '更新产品大类',
},

RCS_PRODUCT_CLASS_GET: {
  code: 'rcs:product_class:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_class',
  resourceName: '产品大类管理',
  actionCode: 'get',
  actionName: '查看产品大类详情',
},

RCS_PRODUCT_CLASS_LIST: {
  code: 'rcs:product_class:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_class',
  resourceName: '产品大类管理',
  actionCode: 'list',
  actionName: '获取产品大类列表',
},

RCS_PRODUCT_CLASS_CREATE: {
  code: 'rcs:product_class:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_class',
  resourceName: '产品大类管理',
  actionCode: 'create',
  actionName: '创建产品大类',
},

RCS_PRODUCT_CLASS_DELETE: {
  code: 'rcs:product_class:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_class',
  resourceName: '产品大类管理',
  actionCode: 'delete',
  actionName: '删除产品大类',
},

RCS_PRODUCT_CONFIGURATION_UPDATE: {
  code: 'rcs:product_configuration:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_configuration',
  resourceName: '产品配置管理',
  actionCode: 'update',
  actionName: '更新产品配置',
},

RCS_PRODUCT_CONFIGURATION_CREATE: {
  code: 'rcs:product_configuration:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_configuration',
  resourceName: '产品配置管理',
  actionCode: 'create',
  actionName: '创建产品配置',
},

RCS_PRODUCT_CONFIGURATION_DELETE: {
  code: 'rcs:product_configuration:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_configuration',
  resourceName: '产品配置管理',
  actionCode: 'delete',
  actionName: '删除产品配置',
},

RCS_PRODUCT_CONFIGURATION_GET: {
  code: 'rcs:product_configuration:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_configuration',
  resourceName: '产品配置管理',
  actionCode: 'get',
  actionName: '查看产品配置详情',
},

RCS_PRODUCT_CONFIGURATION_LIST: {
  code: 'rcs:product_configuration:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_configuration',
  resourceName: '产品配置管理',
  actionCode: 'list',
  actionName: '获取产品配置列表',
},

RCS_PRODUCT_INTERNAL_MODEL_UPDATE: {
  code: 'rcs:product_internal_model:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_internal_model',
  resourceName: '产品型号管理',
  actionCode: 'update',
  actionName: '更新产品型号',
},

RCS_PRODUCT_INTERNAL_MODEL_CREATE: {
  code: 'rcs:product_internal_model:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_internal_model',
  resourceName: '产品型号管理',
  actionCode: 'create',
  actionName: '创建产品型号',
},

RCS_PRODUCT_INTERNAL_MODEL_DELETE: {
  code: 'rcs:product_internal_model:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_internal_model',
  resourceName: '产品型号管理',
  actionCode: 'delete',
  actionName: '删除产品型号',
},

RCS_PRODUCT_INTERNAL_MODEL_GET: {
  code: 'rcs:product_internal_model:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_internal_model',
  resourceName: '产品型号管理',
  actionCode: 'get',
  actionName: '查看产品型号详情',
},

RCS_PRODUCT_INTERNAL_MODEL_LIST: {
  code: 'rcs:product_internal_model:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_internal_model',
  resourceName: '产品型号管理',
  actionCode: 'list',
  actionName: '获取产品型号列表',
},

RCS_PRODUCT_SERVICE_UPDATE: {
  code: 'rcs:product_service:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_service',
  resourceName: '产品服务管理',
  actionCode: 'update',
  actionName: '更新产品服务',
},

RCS_PRODUCT_SERVICE_CREATE: {
  code: 'rcs:product_service:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_service',
  resourceName: '产品服务管理',
  actionCode: 'create',
  actionName: '创建产品服务',
},

RCS_PRODUCT_SERVICE_DELETE: {
  code: 'rcs:product_service:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_service',
  resourceName: '产品服务管理',
  actionCode: 'delete',
  actionName: '删除产品服务',
},

RCS_PRODUCT_SERVICE_GET: {
  code: 'rcs:product_service:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_service',
  resourceName: '产品服务管理',
  actionCode: 'get',
  actionName: '查看产品服务详情',
},

RCS_PRODUCT_SERVICE_LIST: {
  code: 'rcs:product_service:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_service',
  resourceName: '产品服务管理',
  actionCode: 'list',
  actionName: '获取产品服务列表',
},

RCS_PRODUCT_SUB_CLASS_UPDATE: {
  code: 'rcs:product_sub_class:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_sub_class',
  resourceName: '产品小类管理',
  actionCode: 'update',
  actionName: '更新产品小类',
},

RCS_PRODUCT_SUB_CLASS_GET: {
  code: 'rcs:product_sub_class:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_sub_class',
  resourceName: '产品小类管理',
  actionCode: 'get',
  actionName: '查看产品小类详情',
},

RCS_PRODUCT_SUB_CLASS_LIST: {
  code: 'rcs:product_sub_class:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_sub_class',
  resourceName: '产品小类管理',
  actionCode: 'list',
  actionName: '获取产品小类列表',
},

RCS_PRODUCT_SUB_CLASS_CREATE: {
  code: 'rcs:product_sub_class:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_sub_class',
  resourceName: '产品小类管理',
  actionCode: 'create',
  actionName: '创建产品小类',
},

RCS_PRODUCT_SUB_CLASS_DELETE: {
  code: 'rcs:product_sub_class:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_sub_class',
  resourceName: '产品小类管理',
  actionCode: 'delete',
  actionName: '删除产品小类',
},

RCS_PRODUCT_PROPERTY_UPDATE: {
  code: 'rcs:product_property:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_property',
  resourceName: '产品属性管理',
  actionCode: 'update',
  actionName: '更新产品属性',
},

RCS_PRODUCT_PROPERTY_CREATE: {
  code: 'rcs:product_property:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_property',
  resourceName: '产品属性管理',
  actionCode: 'create',
  actionName: '创建产品属性',
},

RCS_PRODUCT_PROPERTY_DELETE: {
  code: 'rcs:product_property:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_property',
  resourceName: '产品属性管理',
  actionCode: 'delete',
  actionName: '删除产品属性',
},

RCS_PRODUCT_PROPERTY_GET: {
  code: 'rcs:product_property:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_property',
  resourceName: '产品属性管理',
  actionCode: 'get',
  actionName: '查看产品属性详情',
},

RCS_PRODUCT_PROPERTY_LIST: {
  code: 'rcs:product_property:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'product_property',
  resourceName: '产品属性管理',
  actionCode: 'list',
  actionName: '获取产品属性列表',
},

RCS_RCS_FUNCTION_REFRESH_IMAGE_TYPES: {
  code: 'rcs:rcs_function:refresh_image_types',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'rcs_function',
  resourceName: 'RcsFunction管理',
  actionCode: 'refresh_image_types',
  actionName: '刷新固件编号数据',
},

RCS_REGION_UPDATE: {
  code: 'rcs:region:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'region',
  resourceName: '区域管理',
  actionCode: 'update',
  actionName: '更新区域',
},

RCS_REGION_GET: {
  code: 'rcs:region:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'region',
  resourceName: '区域管理',
  actionCode: 'get',
  actionName: '查看区域详情',
},

RCS_REGION_LIST: {
  code: 'rcs:region:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'region',
  resourceName: '区域管理',
  actionCode: 'list',
  actionName: '获取区域列表',
},

RCS_REGION_CREATE: {
  code: 'rcs:region:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'region',
  resourceName: '区域管理',
  actionCode: 'create',
  actionName: '创建区域',
},

RCS_REGION_DELETE: {
  code: 'rcs:region:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'region',
  resourceName: '区域管理',
  actionCode: 'delete',
  actionName: '删除区域',
},

RCS_REMOTE_CONTROLLER_DETAIL: {
  code: 'rcs:remote_controller:detail',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'remote_controller',
  resourceName: '码库管理',
  actionCode: 'detail',
  actionName: '查看码库详情',
},

RCS_REMOTE_CONTROLLER_TEST: {
  code: 'rcs:remote_controller:test',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'remote_controller',
  resourceName: '码库管理',
  actionCode: 'test',
  actionName: '码库对码测试',
},

RCS_REMOTE_CONTROLLER_CREATE: {
  code: 'rcs:remote_controller:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'remote_controller',
  resourceName: '码库管理',
  actionCode: 'create',
  actionName: '创建码库控制',
},

RCS_REMOTE_CONTROLLER_UPDATE: {
  code: 'rcs:remote_controller:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'remote_controller',
  resourceName: '码库管理',
  actionCode: 'update',
  actionName: '更新码库控制',
},

RCS_REMOTE_CONTROLLER_DELETE: {
  code: 'rcs:remote_controller:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'remote_controller',
  resourceName: '码库管理',
  actionCode: 'delete',
  actionName: '删除码库控制',
},

RCS_ROLE_UPDATE: {
  code: 'rcs:role:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'role',
  resourceName: '角色管理',
  actionCode: 'update',
  actionName: '更新角色',
},

RCS_ROLE_GET: {
  code: 'rcs:role:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'role',
  resourceName: '角色管理',
  actionCode: 'get',
  actionName: '查看角色详情',
},

RCS_ROLE_LIST: {
  code: 'rcs:role:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'role',
  resourceName: '角色管理',
  actionCode: 'list',
  actionName: '获取角色列表',
},

RCS_ROLE_CREATE: {
  code: 'rcs:role:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'role',
  resourceName: '角色管理',
  actionCode: 'create',
  actionName: '创建角色',
},

RCS_ROLE_DELETE: {
  code: 'rcs:role:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'role',
  resourceName: '角色管理',
  actionCode: 'delete',
  actionName: '删除角色',
},

RCS_SCENE_TYPE_UPDATE: {
  code: 'rcs:scene_type:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'scene_type',
  resourceName: '场景类型管理',
  actionCode: 'update',
  actionName: '修改单个场景类型',
},

RCS_SCENE_TYPE_CREATE: {
  code: 'rcs:scene_type:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'scene_type',
  resourceName: '场景类型管理',
  actionCode: 'create',
  actionName: '创建场景类型',
},

RCS_SCENE_TYPE_GET: {
  code: 'rcs:scene_type:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'scene_type',
  resourceName: '场景类型管理',
  actionCode: 'get',
  actionName: '获取单个场景类型',
},

RCS_SCENE_TYPE_LIST: {
  code: 'rcs:scene_type:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'scene_type',
  resourceName: '场景类型管理',
  actionCode: 'list',
  actionName: '获取场景类型列表',
},

RCS_SERVICE_PROVIDER_UPDATE: {
  code: 'rcs:service_provider:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'service_provider',
  resourceName: '服务商管理',
  actionCode: 'update',
  actionName: '更新服务商',
},

RCS_SERVICE_PROVIDER_CREATE: {
  code: 'rcs:service_provider:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'service_provider',
  resourceName: '服务商管理',
  actionCode: 'create',
  actionName: '创建服务商',
},

RCS_SERVICE_PROVIDER_DELETE: {
  code: 'rcs:service_provider:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'service_provider',
  resourceName: '服务商管理',
  actionCode: 'delete',
  actionName: '删除服务商',
},

RCS_SERVICE_PROVIDER_LIST: {
  code: 'rcs:service_provider:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'service_provider',
  resourceName: '服务商管理',
  actionCode: 'list',
  actionName: '获取服务商列表',
},

RCS_SP_REMOTE_REL_UPDATE: {
  code: 'rcs:sp_remote_rel:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'sp_remote_rel',
  resourceName: '服务商和码库映射关系管理',
  actionCode: 'update',
  actionName: '更新服务商和码库映射关系',
},

RCS_SP_REMOTE_REL_CREATE: {
  code: 'rcs:sp_remote_rel:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'sp_remote_rel',
  resourceName: '服务商和码库映射关系管理',
  actionCode: 'create',
  actionName: '创建服务商和码库映射关系',
},

RCS_SP_REMOTE_REL_DELETE: {
  code: 'rcs:sp_remote_rel:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'sp_remote_rel',
  resourceName: '服务商和码库映射关系管理',
  actionCode: 'delete',
  actionName: '删除服务商和码库映射关系',
},

RCS_SP_REMOTE_REL_LIST: {
  code: 'rcs:sp_remote_rel:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'sp_remote_rel',
  resourceName: '服务商和码库映射关系管理',
  actionCode: 'list',
  actionName: '获取服务商和码库映射关系列表',
},

RCS_TEMPLATE_UPDATE: {
  code: 'rcs:template:update',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'template',
  resourceName: '遥控器模板管理',
  actionCode: 'update',
  actionName: '更新遥控器模板',
},

RCS_TEMPLATE_CREATE: {
  code: 'rcs:template:create',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'template',
  resourceName: '遥控器模板管理',
  actionCode: 'create',
  actionName: '创建遥控器模板',
},

RCS_TEMPLATE_DELETE: {
  code: 'rcs:template:delete',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'template',
  resourceName: '遥控器模板管理',
  actionCode: 'delete',
  actionName: '删除遥控器模板',
},

RCS_TEMPLATE_LIST: {
  code: 'rcs:template:list',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'template',
  resourceName: '遥控器模板管理',
  actionCode: 'list',
  actionName: '获取遥控器模板列表',
},

RCS_TEMPLATE_GET: {
  code: 'rcs:template:get',
  systemCode: '',
  moduleCode: 'rcs',
  moduleName: '',
  resourceCode: 'template',
  resourceName: '遥控器模板管理',
  actionCode: 'get',
  actionName: '查看遥控器模板详情',
},

};
export const permissions = Object.values(Permissions)
  .sort((pre, next) => pre.code.localeCompare(next.code));
export const PermissionsCodeGroup = {

  RCS_BRAND_UPDATE: [
    'rcs:brand:update',
  ],
  RCS_BRAND_CREATE: [
    'rcs:brand:create',
  ],
  RCS_BRAND_DELETE: [
    'rcs:brand:delete',
  ],
  RCS_BRAND_LIST: [
    'rcs:brand:list',
  ],
  RCS_BRAND_REMOTE_REL_UPDATE: [
    'rcs:brand_remote_rel:update',
  ],
  RCS_BRAND_REMOTE_REL_CREATE: [
    'rcs:brand_remote_rel:create',
  ],
  RCS_BRAND_REMOTE_REL_DELETE: [
    'rcs:brand_remote_rel:delete',
  ],
  RCS_BRAND_REMOTE_REL_LIST: [
    'rcs:brand_remote_rel:list',
  ],
  RCS_CPU_FEATURE_DELETE: [
    'rcs:cpu_feature:delete',
  ],
  RCS_CPU_FEATURE_UPDATE: [
    'rcs:cpu_feature:update',
  ],
  RCS_CPU_FEATURE_CREATE: [
    'rcs:cpu_feature:create',
  ],
  RCS_CPU_FEATURE_GET: [
    'rcs:cpu_feature:get',
  ],
  RCS_CPU_FEATURE_LIST: [
    'rcs:cpu_feature:list',
  ],
  RCS_CPU_FEATURE_RELATION_UPDATE: [
    'rcs:cpu_feature_relation:update',
  ],
  RCS_CPU_FEATURE_RELATION_CREATE: [
    'rcs:cpu_feature_relation:create',
  ],
  RCS_CPU_FEATURE_RELATION_GET: [
    'rcs:cpu_feature_relation:get',
  ],
  RCS_CPU_FEATURE_RELATION_LIST: [
    'rcs:cpu_feature_relation:list',
  ],
  RCS_CPU_FEATURE_RELATION_DELETE: [
    'rcs:cpu_feature_relation:delete',
  ],
  RCS_CPU_FEATURE_RELATION_PUBLISH: [
    'rcs:cpu_feature_relation:publish',
  ],
  RCS_CPU_HARD_VERSION_DELETE: [
    'rcs:cpu_hard_version:delete',
  ],
  RCS_CPU_HARD_VERSION_UPDATE: [
    'rcs:cpu_hard_version:update',
  ],
  RCS_CPU_HARD_VERSION_CREATE: [
    'rcs:cpu_hard_version:create',
  ],
  RCS_CPU_HARD_VERSION_GET: [
    'rcs:cpu_hard_version:get',
  ],
  RCS_CPU_HARD_VERSION_LIST: [
    'rcs:cpu_hard_version:list',
  ],
  RCS_CPU_MODEL_DELETE: [
    'rcs:cpu_model:delete',
  ],
  RCS_CPU_MODEL_UPDATE: [
    'rcs:cpu_model:update',
  ],
  RCS_CPU_MODEL_CREATE: [
    'rcs:cpu_model:create',
  ],
  RCS_CPU_MODEL_GET: [
    'rcs:cpu_model:get',
  ],
  RCS_CPU_MODEL_LIST: [
    'rcs:cpu_model:list',
  ],
  RCS_CPU_SOFT_VERSION_DELETE: [
    'rcs:cpu_soft_version:delete',
  ],
  RCS_CPU_SOFT_VERSION_UPDATE: [
    'rcs:cpu_soft_version:update',
  ],
  RCS_CPU_SOFT_VERSION_CREATE: [
    'rcs:cpu_soft_version:create',
  ],
  RCS_CPU_SOFT_VERSION_GET: [
    'rcs:cpu_soft_version:get',
  ],
  RCS_CPU_SOFT_VERSION_LIST: [
    'rcs:cpu_soft_version:list',
  ],
  RCS_DATE_INFORMATION_UPDATE: [
    'rcs:date_information:update',
  ],
  RCS_DATE_INFORMATION_CREATE: [
    'rcs:date_information:create',
  ],
  RCS_DATE_INFORMATION_DELETE: [
    'rcs:date_information:delete',
  ],
  RCS_DATE_INFORMATION_LIST: [
    'rcs:date_information:list',
  ],
  RCS_DEVICE_TYPE_UPDATE: [
    'rcs:device_type:update',
  ],
  RCS_DEVICE_TYPE_CREATE: [
    'rcs:device_type:create',
  ],
  RCS_DEVICE_TYPE_DELETE: [
    'rcs:device_type:delete',
  ],
  RCS_DEVICE_TYPE_LIST: [
    'rcs:device_type:list',
  ],
  RCS_DISTRICT_GET: [
    'rcs:district:get',
  ],
  RCS_DISTRICT_LIST: [
    'rcs:district:list',
  ],
  RCS_DLL_FILE_UPDATE: [
    'rcs:dll_file:update',
  ],
  RCS_DLL_FILE_CREATE: [
    'rcs:dll_file:create',
  ],
  RCS_DLL_FILE_GET: [
    'rcs:dll_file:get',
  ],
  RCS_DLL_FILE_LIST: [
    'rcs:dll_file:list',
  ],
  RCS_DLL_FILE_DELETE: [
    'rcs:dll_file:delete',
  ],
  RCS_ENTITY_LANGUAGE_LIST: [
    'rcs:entity_language:list',
  ],
  RCS_ENTITY_LANGUAGE_CREATE: [
    'rcs:entity_language:create',
  ],
  RCS_ENTITY_LANGUAGE_DELETE: [
    'rcs:entity_language:delete',
  ],
  RCS_ENTRY_I18N_UPDATE: [
    'rcs:entry_i18n:update',
  ],
  RCS_ENTRY_I18N_LIST: [
    'rcs:entry_i18n:list',
  ],
  RCS_ENTRY_I18N_ENTITY_UPDATE: [
    'rcs:entry_i18n_entity:update',
  ],
  RCS_ENTRY_I18N_ENTITY_CREATE: [
    'rcs:entry_i18n_entity:create',
  ],
  RCS_ENTRY_I18N_ENTITY_DELETE: [
    'rcs:entry_i18n_entity:delete',
  ],
  RCS_ENTRY_I18N_ENTITY_LIST: [
    'rcs:entry_i18n_entity:list',
  ],
  RCS_ENTRY_I18N_LANGUAGE_UPDATE: [
    'rcs:entry_i18n_language:update',
  ],
  RCS_ENTRY_I18N_LANGUAGE_CREATE: [
    'rcs:entry_i18n_language:create',
  ],
  RCS_ENTRY_I18N_LANGUAGE_DELETE: [
    'rcs:entry_i18n_language:delete',
  ],
  RCS_ENTRY_I18N_LANGUAGE_LIST: [
    'rcs:entry_i18n_language:list',
  ],
  RCS_FILE_UPLOAD: [
    'rcs:file:upload',
  ],
  RCS_I18N_CREATE: [
    'rcs:i18n:create',
  ],
  RCS_I18N_LIST: [
    'rcs:i18n:list',
  ],
  RCS_INFRARED_TEMPLATE_GET: [
    'rcs:infrared_template:get',
  ],
  RCS_INFRARED_TEMPLATE_LIST: [
    'rcs:infrared_template:list',
  ],
  RCS_IR_KEY_LIST: [
    'rcs:ir_key:list',
  ],
  RCS_LDAP_USER_UPDATE: [
    'rcs:ldap_user:update',
  ],
  RCS_LDAP_USER_GET: [
    'rcs:ldap_user:get',
  ],
  RCS_LDAP_USER_LIST: [
    'rcs:ldap_user:list',
  ],
  RCS_LOADED_DEVICE_TYPE_UPDATE: [
    'rcs:loaded_device_type:update',
  ],
  RCS_LOADED_DEVICE_TYPE_GET: [
    'rcs:loaded_device_type:get',
  ],
  RCS_LOADED_DEVICE_TYPE_LIST: [
    'rcs:loaded_device_type:list',
  ],
  RCS_LOADED_DEVICE_TYPE_CREATE: [
    'rcs:loaded_device_type:create',
  ],
  RCS_LOADED_DEVICE_TYPE_DELETE: [
    'rcs:loaded_device_type:delete',
  ],
  RCS_LOADED_DEVICE_TYPE_LIST_FROM_CACHE: [
    'rcs:loaded_device_type:list_from_cache',
  ],
  RCS_PLATFORM_UPDATE: [
    'rcs:platform:update',
  ],
  RCS_PLATFORM_CREATE: [
    'rcs:platform:create',
  ],
  RCS_PLATFORM_GET: [
    'rcs:platform:get',
  ],
  RCS_PLATFORM_LIST: [
    'rcs:platform:list',
  ],
  RCS_PLATFORM_DELETE: [
    'rcs:platform:delete',
  ],
  RCS_PLATFORM_SECRET: [
    'rcs:platform:secret',
  ],
  RCS_PLATFORM_REFRESH: [
    'rcs:platform:refresh',
  ],
  RCS_PRODUCT_UPDATE: [
    'rcs:product:update',
  ],
  RCS_PRODUCT_CREATE: [
    'rcs:product:create',
  ],
  RCS_PRODUCT_GET: [
    'rcs:product:get',
  ],
  RCS_PRODUCT_LIST: [
    'rcs:product:list',
  ],
  RCS_PRODUCT_LOADED_DEVICE_TYPE_ID_UPDATE: [
    'rcs:product_loaded_device_type_id:update',
  ],
  RCS_PRODUCT_CATEGORY_UPDATE: [
    'rcs:product_category:update',
  ],
  RCS_PRODUCT_CATEGORY_GET: [
    'rcs:product_category:get',
  ],
  RCS_PRODUCT_CATEGORY_LIST: [
    'rcs:product_category:list',
  ],
  RCS_PRODUCT_CATEGORY_CREATE: [
    'rcs:product_category:create',
  ],
  RCS_PRODUCT_CATEGORY_DELETE: [
    'rcs:product_category:delete',
  ],
  RCS_PRODUCT_CLASS_UPDATE: [
    'rcs:product_class:update',
  ],
  RCS_PRODUCT_CLASS_GET: [
    'rcs:product_class:get',
  ],
  RCS_PRODUCT_CLASS_LIST: [
    'rcs:product_class:list',
  ],
  RCS_PRODUCT_CLASS_CREATE: [
    'rcs:product_class:create',
  ],
  RCS_PRODUCT_CLASS_DELETE: [
    'rcs:product_class:delete',
  ],
  RCS_PRODUCT_CONFIGURATION_UPDATE: [
    'rcs:product_configuration:update',
  ],
  RCS_PRODUCT_CONFIGURATION_CREATE: [
    'rcs:product_configuration:create',
  ],
  RCS_PRODUCT_CONFIGURATION_DELETE: [
    'rcs:product_configuration:delete',
  ],
  RCS_PRODUCT_CONFIGURATION_GET: [
    'rcs:product_configuration:get',
  ],
  RCS_PRODUCT_CONFIGURATION_LIST: [
    'rcs:product_configuration:list',
  ],
  RCS_PRODUCT_INTERNAL_MODEL_UPDATE: [
    'rcs:product_internal_model:update',
  ],
  RCS_PRODUCT_INTERNAL_MODEL_CREATE: [
    'rcs:product_internal_model:create',
  ],
  RCS_PRODUCT_INTERNAL_MODEL_DELETE: [
    'rcs:product_internal_model:delete',
  ],
  RCS_PRODUCT_INTERNAL_MODEL_GET: [
    'rcs:product_internal_model:get',
  ],
  RCS_PRODUCT_INTERNAL_MODEL_LIST: [
    'rcs:product_internal_model:list',
  ],
  RCS_PRODUCT_SERVICE_UPDATE: [
    'rcs:product_service:update',
  ],
  RCS_PRODUCT_SERVICE_CREATE: [
    'rcs:product_service:create',
  ],
  RCS_PRODUCT_SERVICE_DELETE: [
    'rcs:product_service:delete',
  ],
  RCS_PRODUCT_SERVICE_GET: [
    'rcs:product_service:get',
  ],
  RCS_PRODUCT_SERVICE_LIST: [
    'rcs:product_service:list',
  ],
  RCS_PRODUCT_SUB_CLASS_UPDATE: [
    'rcs:product_sub_class:update',
  ],
  RCS_PRODUCT_SUB_CLASS_GET: [
    'rcs:product_sub_class:get',
  ],
  RCS_PRODUCT_SUB_CLASS_LIST: [
    'rcs:product_sub_class:list',
  ],
  RCS_PRODUCT_SUB_CLASS_CREATE: [
    'rcs:product_sub_class:create',
  ],
  RCS_PRODUCT_SUB_CLASS_DELETE: [
    'rcs:product_sub_class:delete',
  ],
  RCS_PRODUCT_PROPERTY_UPDATE: [
    'rcs:product_property:update',
  ],
  RCS_PRODUCT_PROPERTY_CREATE: [
    'rcs:product_property:create',
  ],
  RCS_PRODUCT_PROPERTY_DELETE: [
    'rcs:product_property:delete',
  ],
  RCS_PRODUCT_PROPERTY_GET: [
    'rcs:product_property:get',
  ],
  RCS_PRODUCT_PROPERTY_LIST: [
    'rcs:product_property:list',
  ],
  RCS_RCS_FUNCTION_REFRESH_IMAGE_TYPES: [
    'rcs:rcs_function:refresh_image_types',
  ],
  RCS_REGION_UPDATE: [
    'rcs:region:update',
  ],
  RCS_REGION_GET: [
    'rcs:region:get',
  ],
  RCS_REGION_LIST: [
    'rcs:region:list',
  ],
  RCS_REGION_CREATE: [
    'rcs:region:create',
  ],
  RCS_REGION_DELETE: [
    'rcs:region:delete',
  ],
  RCS_REMOTE_CONTROLLER_DETAIL: [
    'rcs:remote_controller:detail',
  ],
  RCS_REMOTE_CONTROLLER_TEST: [
    'rcs:remote_controller:test',
  ],
  RCS_REMOTE_CONTROLLER_CREATE: [
    'rcs:remote_controller:create',
  ],
  RCS_REMOTE_CONTROLLER_UPDATE: [
    'rcs:remote_controller:update',
  ],
  RCS_REMOTE_CONTROLLER_DELETE: [
    'rcs:remote_controller:delete',
  ],
  RCS_ROLE_UPDATE: [
    'rcs:role:update',
  ],
  RCS_ROLE_GET: [
    'rcs:role:get',
  ],
  RCS_ROLE_LIST: [
    'rcs:role:list',
  ],
  RCS_ROLE_CREATE: [
    'rcs:role:create',
  ],
  RCS_ROLE_DELETE: [
    'rcs:role:delete',
  ],
  RCS_SCENE_TYPE_UPDATE: [
    'rcs:scene_type:update',
  ],
  RCS_SCENE_TYPE_CREATE: [
    'rcs:scene_type:create',
  ],
  RCS_SCENE_TYPE_GET: [
    'rcs:scene_type:get',
  ],
  RCS_SCENE_TYPE_LIST: [
    'rcs:scene_type:list',
  ],
  RCS_SERVICE_PROVIDER_UPDATE: [
    'rcs:service_provider:update',
  ],
  RCS_SERVICE_PROVIDER_CREATE: [
    'rcs:service_provider:create',
  ],
  RCS_SERVICE_PROVIDER_DELETE: [
    'rcs:service_provider:delete',
  ],
  RCS_SERVICE_PROVIDER_LIST: [
    'rcs:service_provider:list',
  ],
  RCS_SP_REMOTE_REL_UPDATE: [
    'rcs:sp_remote_rel:update',
  ],
  RCS_SP_REMOTE_REL_CREATE: [
    'rcs:sp_remote_rel:create',
  ],
  RCS_SP_REMOTE_REL_DELETE: [
    'rcs:sp_remote_rel:delete',
  ],
  RCS_SP_REMOTE_REL_LIST: [
    'rcs:sp_remote_rel:list',
  ],
  RCS_TEMPLATE_UPDATE: [
    'rcs:template:update',
  ],
  RCS_TEMPLATE_CREATE: [
    'rcs:template:create',
  ],
  RCS_TEMPLATE_DELETE: [
    'rcs:template:delete',
  ],
  RCS_TEMPLATE_LIST: [
    'rcs:template:list',
  ],
  RCS_TEMPLATE_GET: [
    'rcs:template:get',
  ],
};
