import http from '../http-engine';

export default {
  list(params) {
    return http.get('/api/v1/roles', {params});
  },
  create(params) {
    return http.post('/api/v1/roles', params);
  },
  getById(id) {
    return http.get(`/api/v1/roles/${id}`);
  },
  deleteById(id) {
    return http.delete(`/api/v1/roles/${id}`);
  },
  update(params) {
    return http.patch(`/api/v1/roles/${params.data.id}`, {role: params});
  },
};
