<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import lodash from 'lodash';

export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  methods: {
    async refreshUser() {
      const res = await this.$apiManager.admin.refresh();
      const { currentUser } = this.$store.state;
      Object.assign(currentUser, res.data);
      currentUser.accessToken = `Bearer ${res.data.accessToken}`;
      this.$store.commit('setUserInfo', currentUser);
    },
    async waitRoute() {
      // 等待this.$route初始化完成
      if (!this.$route.name) {
        setTimeout(() => this.waitRoute(), 100);
        return;
      }
      // 匿名页不需要获取新token
      const whiteList = ['ModelProductList', 'ModelLoadDeviceList', 'modelProductDetail', 'modelLoadedDeviceDetail'];
      if (this.$route.name && whiteList.includes(this.$route.name)) {
        return;
      }
      await this.refreshUser();
      if (this.$route.meta.permissionCodeList) {
        // 更新权限后判断当前用户是否有当前路由的权限,若无，则跳转403界面
        if (
          lodash.intersection(this.$route.meta.permissionCodeList, this.$store.state.currentUser.permissions).length
          === 0
        ) {
          this.$router.push({ name: 'error403' });
        }
      }
    },
  },
  mounted() {
    this.$store.commit('initData');
    this.waitRoute();
  },
};
</script>
<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
