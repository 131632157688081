import http from '../http-engine';

export default {
  getProductCategories(params, headers) {
    return http.get('/api/v1/productCategories', { params, headers });
  },
  createProductCategories(params) {
    return http.post('/api/v1/productCategories', params);
  },
  updateProductCategories(params) {
    const param = {
      fields: ['name'],
      data: params,
    };
    return http.patch(`/api/v1/productCategories/${params.id}`, param);
  },
  deleteProductCategories(params) {
    return http.delete(`/api/v1/productCategories/${params}`);
  },
  updateSort(params) {
    return http.post('/api/v1/productCategories:updateSort', params);
  },
};
