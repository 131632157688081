import http from '../http-engine';

export default {
  getProductClasses(params, headers) {
    return http.get(
      `/api/v1/productCategories/${params.productCategoryId ? params.productCategoryId : '-'}/productClasses`,
      { params, headers },
    );
  },
  createProductClasses(params) {
    return http.post(`/api/v1/productCategories/${params.productCategoryId}/productClasses`, params);
  },
  updateProductClasses(params) {
    const param = {
      fields: ['name', 'productCategoryId'],
      data: params,
    };
    return http.patch(`/api/v1/productCategories/-/productClasses/${param.data.id}`, param);
  },
  deleteProductClasses(params) {
    return http.delete(`/api/v1/productCategories/-/productClasses/${params}`);
  },
  updateSort(params) {
    return http.post('/api/v1/productCategories/-/productClasses:updateSort', params);
  },
};
