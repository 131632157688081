var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"width":"60%","visible":_vm.visible,"title":_vm.role.name,"maskClosable":false},on:{"close":_vm.handleClose}},[_c('a-row',{staticStyle:{"margin-bottom":"1rem"}},[_c('a-col',{attrs:{"span":6,"offset":18}},[_c('a-input-search',{attrs:{"placeholder":"请输入模块名称"},on:{"search":_vm.handleSearch},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1),_c('a-table',{staticClass:"permission-table",staticStyle:{"margin-bottom":"3rem"},attrs:{"dataSource":_vm.tableData,"pagination":false,"row-key":"module"}},[_c('a-table-column',{key:"moduleName",attrs:{"data-index":"moduleName","title":"模块"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a-checkbox',{attrs:{"checked":_vm.moduleIsChecked(scope)},on:{"change":(e)=>_vm.handleModuleChange(scope,e.target.checked)}},[_vm._v(" "+_vm._s(scope)+" ")])]}}])}),_c('a-table-column',{key:"permissions",attrs:{"data-index":"permissions","title":"权限"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a-row',{attrs:{"gutter":[24, 16]}},_vm._l((scope),function(permission){return _c('a-col',{key:permission.code,attrs:{"span":_vm.permissionCol(scope.length)}},[_c('a-checkbox',{attrs:{"checked":_vm.isChecked(permission.code)},on:{"change":(e) => _vm.handlePermissionChange([permission.code], e.target.checked)}},[_vm._v(" "+_vm._s(permission.actionName)+" ")])],1)}),1)]}}])})],1),_c('div',{style:({
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      })},[_c('a-button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.handleClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"loading":_vm.confirmLoading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 确认 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }