const FILTER_OPTION_ZH_CN = {
  value: 'zh-CN',
  label: '简体中文',
};
const FILTER_OPTION_ZH_TW = {
  value: 'zh-TW',
  label: '繁体中文',
};
const FILTER_OPTION_EN = {
  value: 'en',
  label: '英文',
};
const FILTER_OPTIONS = [FILTER_OPTION_ZH_CN, FILTER_OPTION_ZH_TW, FILTER_OPTION_EN];
const columns = [
  {
    title: '国际化语言编号',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: '国际化语言名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' },
    align: 'center',
  },
  {
    title: '上次修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  columns,
  FILTER_OPTIONS,
  FILTER_OPTION_ZH_CN,
  FILTER_OPTION_ZH_TW,
  FILTER_OPTION_EN,
};
