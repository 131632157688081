const utils = {
  /**
   * 浏览器相关工具
   */
  browser: {
    versions() {
      const u = navigator.userAgent;
      return {
        trident: u.indexOf('Trident') > -1, // IE内核
        presto: u.indexOf('Presto') > -1, // opera内核
        webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
        iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, // 是否iPad
        webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
        weixin: u.indexOf('MicroMessenger') > -1, // 是否微信 （2015-01-22新增）
        qq: u.match(/\sQQ/i) === ' qq', // 是否QQ
      };
    },
    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
  },
  /**
   * 数组工具
   */
  array: {
    intersection(arrayA, arrayB) {
      if (!arrayA || !arrayB) {
        return false;
      }
      return !!arrayA.find((aItem) => arrayB.find((bItem) => aItem === bItem));
    },
    compare(property) {
      return function ArraySort(a, b) {
        if (a && b) {
          const value1 = a[property];
          const value2 = b[property];
          return value1 - value2;
        }
      };
    },
    findIndex(ary, fn) {
      if (ary.findIndex) {
        return ary.findIndex(fn);
      }
      /* istanbul ignore next */
      let index = -1;
      /* istanbul ignore next */
      ary.some((item, i, list) => {
        const ret = fn.call(this, item, i, list);
        if (ret) {
          index = i;
        }
        return ret;
      });
      /* istanbul ignore next */
      return index;
    },
    removeContained(array, item) {
      const index = this.indexOfContained(array, item);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    remove(array, item) {
      const index = this.indexOf(array, item);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    indexOf(array, item) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[i] === item) return i;
      }
      return -1;
    },
    indexOfContained(array, item) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[i].indexOf(item) !== -1 || item.indexOf(array[i]) !== -1) return i;
      }
      return -1;
    },
    contained(array, item) {
      return array.filter((bean) => bean === item).length > 0;
    },
  },

  common: {
    objectToString(o) {
      return Object.prototype.toString.call(o);
    },

    isArray(arg) {
      if (Array.isArray) {
        return Array.isArray(arg);
      }
      return this.objectToString(arg) === '[object Array]';
    },

    isBoolean(arg) {
      return typeof arg === 'boolean';
    },

    isNull(arg) {
      return arg === null;
    },

    isNullOrUndefined(arg) {
      return arg === null || arg === undefined || arg === 'null' || arg === 'undefined';
    },

    isNumber(arg) {
      return typeof arg === 'number';
    },

    isString(arg) {
      return typeof arg === 'string';
    },

    isSymbol(arg) {
      return typeof arg === 'symbol';
    },

    isUndefined(arg) {
      return arg === undefined;
    },

    isRegExp(re) {
      return this.objectToString(re) === '[object RegExp]';
    },

    isObject(arg) {
      return typeof arg === 'object' && arg !== null;
    },

    isDate(d) {
      return this.objectToString(d) === '[object Date]';
    },

    isError(e) {
      return this.objectToString(e) === '[object Error]' || e instanceof Error;
    },

    isFunction(arg) {
      return typeof arg === 'function';
    },

    isPrimitive(arg) {
      return (
        arg === null
        || typeof arg === 'boolean'
        || typeof arg === 'number'
        || typeof arg === 'string'
        || typeof arg === 'symbol'
        || typeof arg === 'undefined'
      );
    },
  },
};
export default utils;
