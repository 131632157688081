import http from '../http-engine';
import store from '../../core/store';

export default {
  uploadFile(module, submodule, file) {
    const formData = new FormData();
    formData.append('module', module);
    formData.append('submodule', submodule);
    formData.append('file', file);
    const config = {
      onUploadProgress: (progressEvent) => {
        store.commit('setUploadProgress', progressEvent);
      },
    };
    return http.post('/api/v1/files', formData, config);
  },
};
