import http from '../http-engine';

export default {
  getUsers(params) {
    return http.get('/management/api/v1/user/getUsers', { params });
  },
  getManagersList(params) {
    return http.get('/management/api/v1/user/getManagers', { params });
  },
  // signUpUser(params) {
  //   return http.post('/management/api/v1/gateway/user/login', params);
  // },
  getRelatedUsers(params) {
    return http.get('/management/api/v1/user/getRelatedUsers', { params });
  },
  updateUser(params) {
    return http.put('/management/api/v1/user/updateUser', params);
  },
  /**
   * 修改自己的信息
   * @param 用户姓名 name
   * @param 用户手机号 phone
   */
  updateSelf(openId, name, phone) {
    return http.patch('/management/api/v1/user/updateSelfInfo', { openId, name, phone });
  },
};
