import http from '../http-engine';

export default {
  getProductModel(params) {
    return http.get('/api/v1/productInternalModels', { params });
  },
  getProductModelById(id) {
    return http.get(`/api/v1/productInternalModels/${id}`);
  },
  createProductModel(params) {
    return http.post('/api/v1/productInternalModels', params);
  },
  updateProductService(params) {
    const param = {
      fields: ['description', 'name', 'productIds'],
      data: params,
    };
    return http.patch(`/api/v1/productInternalModels/${params.id}`, param);
  },
  deleteProductModel(id) {
    return http.delete(`/api/v1/productInternalModels/${id}`);
  },
};
