import http from '../http-engine';

export default {
  getEntityLanguages(params) {
    return http.get('/api/v1/entityLanguages', { params });
  },
  createEntityLanguage(params) {
    return http.post('/api/v1/entityLanguages', params);
  },
  deleteEntityLanguage(params) {
    return http.delete(`/api/v1/entityLanguages/${params}`);
  },
};
