const columns = [
  {
    title: '中控型号',
    dataIndex: 'model',
    key: 'model',
    align: 'center',
    sorter: true,
  },
  {
    title: '中控硬件版本',
    dataIndex: 'hardVersion',
    key: 'hardVersion',
    align: 'center',
    sorter: true,
  },
  {
    title: '中控软件版本',
    dataIndex: 'softVersion',
    key: 'softVersion',
    align: 'center',
    sorter: true,
  },
  {
    title: '中控功能',
    dataIndex: 'features',
    key: 'features',
    align: 'center',
    scopedSlots: { customRender: 'features' },
  },
  {
    title: '最后修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    align: 'center',
    scopedSlots: { customRender: 'lastModificationTime' },
    sorter: true,
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  columns,
};
