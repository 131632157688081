import http from '../http-engine';

export default {
  getProducts(param) {
    const params = {
      params: param,
      headers: {
        'FULL-PRODUCT': true,
      },
    };
    return http.get('/api/v1/products', params);
  },
  getProductById(productId, headers) {
    return http.get(`/api/v1/products/${productId}`, { headers });
  },
  createProduct(params) {
    return http.post('/api/v1/products', params);
  },
  updateProduct(params) {
    const param = {
      fields: [
        'name',
        'driver',
        'protocol',
        'type',
        'addressType',
        'subAddressType',
        'children',
        'childrenCount',
        'childrenCountLimit',
        'userVisible',
        'imageTypeIds',
        'deviceIds',
        'metadata',
        'configurationItemIds',
        'propertyIds',
        'serviceIds',
        'regionIdentifiers',
        'loadedDeviceTypeIds',
        'icon',
        'useFrequency',
        'remark',
        'internalModelIds',
      ],
      data: params,
    };
    return http.patch(`/api/v1/products/${params.productId}`, param);
  },
  appUpdateProduct(params) {
    const param = {
      fields: ['loadedDeviceTypeIds'],
      data: params,
    };
    return http.patch(`/api/v1/products/${params.productId}`, param);
  },
  deleteProduct(params) {
    return http.delete(`/api/v1/products/${params}`);
  },
  getPropertiesByLoadedDeviceTypeIds(params) {
    return http.get(`/api/v1/products/${params.loadedDeviceTypeIds}:batchGetProperties`, { params: { unpaged: true } });
  },
};
