import http from '../http-engine';

export default {
  getServiceProviders(params) {
    return http.get('/api/v1/serviceProviders/', { params });
  },
  createServiceProviders(params) {
    return http.post('/api/v1/serviceProviders/', params);
  },
  updateServiceProviders(params) {
    return http.put('/api/v1/serviceProviders/', params);
  },
  deleteServiceProviders(params) {
    return http.delete(`/api/v1/serviceProviders/${params}`);
  },
  searchServiceProviders(params) {
    return http.get(`/api/v1/spRemoteRels/${params}/serviceprovider/`);
  },
};
