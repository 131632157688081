import http from '../http-engine';

export default {
  getUsers(params) {
    return http.get('/api/v1/ldapUser', { params });
  },
  getUserByOpenId(openId) {
    return http.get(`/api/v1/ldapUser/${openId}`);
  },
  updateUserRole(params) {
    return http.post('/api/v1/ldapUser/role', params);
  },
};
