const FILTER_OPTION_PRODUCT_SUB_CLASS_SUB_CLASS = {
  value: 0,
  label: '产品小类',
};
const FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CATEGORY = {
  value: 1,
  label: '产品目录',
};
const FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CLASS = {
  value: 2,
  label: '产品大类',
};
const FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_PRODUCT_ID = {
  value: 3,
  label: '物模型ID',
};
const FILTER_OPTIONS = [
  FILTER_OPTION_PRODUCT_SUB_CLASS_SUB_CLASS,
  FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CATEGORY,
  FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CLASS,
  FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_PRODUCT_ID,
];
const PRODUCT_SUB_CLASS_TYPE = {
  0: '网关',
  1: '单品',
  2: '第三方产品',
};
const PRODUCT_SUB_CLASS_KIND = {
  1: '家居类',
  0: '社区类',
};
const PRODUCT_SUB_CLASS_TYPE_OPTIONS = [
  {
    value: 0,
    label: '第三方产品',
  },
  {
    value: 1,
    label: '网关',
  },
  {
    value: 2,
    label: '单品',
  },
];
const PRODUCT_SUB_CLASS_KIND_OPTIONS = [
  {
    value: 1,
    label: '家居类',
  },
  {
    value: 0,
    label: '社区类',
  },
];
const columns = [
  {
    title: '产品小类名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '产品大类名称',
    dataIndex: 'productClassName',
    key: 'productClassName',
    align: 'center',
  },
  {
    title: '产品目录名称',
    dataIndex: 'productCategoryName',
    key: 'productCategoryName',
    align: 'center',
  },
  {
    title: '产品小类类别',
    dataIndex: 'kind',
    scopedSlots: { customRender: 'kind' },
    key: 'kind',
    align: 'center',
  },
  {
    title: '产品小类类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
    align: 'center',
  },
  {
    title: '发布区域',
    dataIndex: 'regionIdentifiers',
    key: 'regionIdentifiers',
    scopedSlots: { customRender: 'regionIdentifiers' },
    align: 'center',
  },
  {
    title: '物模型ID',
    dataIndex: 'productId',
    key: 'productId',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

function productSubClassType(type) {
  switch (type) {
    case 0:
      return '第三方产品';
    case 1:
      return '网关';
    case 2:
      return '单品';
    default:
      return '';
  }
}

function productSubClassKind(kind) {
  switch (kind) {
    case 0:
      return '社区类';
    case 1:
      return '家居类';
    default:
      return '';
  }
}
export default {
  FILTER_OPTION_PRODUCT_SUB_CLASS_SUB_CLASS,
  FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CATEGORY,
  FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_CLASS,
  FILTER_OPTION_PRODUCT_SUB_CLASS_PRODUCT_PRODUCT_ID,
  FILTER_OPTIONS,
  columns,
  PRODUCT_SUB_CLASS_TYPE,
  PRODUCT_SUB_CLASS_KIND,
  PRODUCT_SUB_CLASS_TYPE_OPTIONS,
  PRODUCT_SUB_CLASS_KIND_OPTIONS,
  productSubClassType,
  productSubClassKind,
};
