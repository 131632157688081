const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    colSpan: 0,
    customRender: (value) => {
      const obj = {
        children: value,
        attrs: {},
      };

      obj.attrs.colSpan = 0;
      return obj;
    },
  },
  {
    title: '年份',
    dataIndex: 'year',
    key: 'year',
    align: 'center',
  },
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
    scopedSlots: { customRender: 'date' },
    align: 'center',
  },
  {
    title: '日期类型',
    dataIndex: 'dateType',
    key: 'dateType',
    scopedSlots: { customRender: 'dateType' },
    align: 'center',
    filterMultiple: false,
    filters: [
      {
        text: '节假日',
        value: '2',
      },
      {
        text: '工作日',
        value: '1',
      },
    ],
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center',
  },
  {
    title: '最后修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default { columns };
