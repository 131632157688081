import http from '../http-engine';

const productApi = {
  getProducts(param) {
    const params = {
      params: param,
      headers: {
        'FULL-PRODUCT': true,
      },
    };
    return http.get('/api/v1/publicProducts', params);
  },
  getProductById(productId, headers) {
    return http.get(`/api/v1/publicProducts/${productId}`, { headers });
  },
};
const loadDeviceApi = {
  getLoadedDeviceTypes(params) {
    return http.get('/api/v1/publicLoadedDeviceTypes', { params });
  },
  getLoadedDeviceTypeById(params, headers) {
    return http.get(`/api/v1/publicLoadedDeviceTypes/${params.loadedDeviceTypeId}`, { headers });
  },
};

export default {
  productApi,
  loadDeviceApi,
};
