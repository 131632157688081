import http from '../http-engine';

export default {
  getScenceTypeList(params, headers) {
    return http.get('/api/v1/sceneTypes', { params, headers });
  },
  getScenceType(id) {
    return http.get(`/api/v1/sceneTypes/${id}`);
  },
  createScenceType(params) {
    return http.post('/api/v1/sceneTypes', params);
  },
  updateScenceType(params) {
    return http.put(`/api/v1/sceneTypes/${params.id}`, params);
  },
  updateStatus(id, status) {
    return http.post(`/api/v1/sceneTypes/${id}:updateStatus`, { status });
  },
};
