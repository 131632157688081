import lodash from 'lodash';

const LEVEL_TYPE = {
  subMenu: 1,
  menuGroup: 2,
  menuItem: 3,
};

const defaultOptions = {
  navBarVisible: true,
  levelType: LEVEL_TYPE.menuItem,
  redirect: 'no-redirect',
};

class BaseRouterNavItem {
  /**
   *
   * @param {*} path
   * @param {*} name
   * @param {*} component
   * @param {*} title
   * @param {*} children
   * @param {*} options
   */
  constructor(path, name, title, component, options, children) {
    this.path = path;
    this.name = name;
    this.component = component;
    this.title = title;
    this.children = children;
    this.options = options;
    this.meta = { title, ...options };
    this.redirect = options ? options.redirect : '';
    this.meta = lodash.defaults(this.meta, defaultOptions);
  }
}

export default BaseRouterNavItem;
