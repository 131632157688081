const columns = [
  {
    title: '中控功能',
    dataIndex: 'feature',
    key: 'feature',
    align: 'center',
    sorter: true,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
  },
  {
    title: '最后修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    align: 'center',
    scopedSlots: { customRender: 'lastModificationTime' },
    sorter: true,
    width: '15%',
  },
  {
    title: '操作',
    key: 'action',
    width: '25%',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
];
const selectOption = [
  {
    value: 'feature',
    label: '中控功能',
  },
  {
    value: 'remark',
    label: '备注',
  },
];
const optionEnum = {
  FEATURE: 'feature',
  REMARK: 'remark',
};
export default {
  columns,
  selectOption,
  optionEnum,
};
