const FILTER_OPTION_REGION_NAME = {
  value: '0',
  label: '区域名称',
};
const columns = [
  {
    title: '区域名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '区域标识符',
    dataIndex: 'identifier',
    key: 'identifier',
    align: 'center',
  },
  {
    title: '区域服务器地址',
    dataIndex: 'host',
    key: 'host',
    align: 'center',
  },
  {
    title: '区域标签',
    dataIndex: 'tag',
    key: 'tag',
    align: 'center',
  },
  {
    title: '创建时间',
    key: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center',
  },
  {
    title: '上次修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'creatorName',
    key: 'creatorName',
    align: 'center',
  },
  {
    title: '区域推送',
    key: 'pushStatus',
    scopedSlots: { customRender: 'pushStatus' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  FILTER_OPTION_REGION_NAME,
  columns,
};
