import admin from './model/api-admin';
import user from './model/api-user';
import serviceProvider from './model/api-serviceprovider';
import brand from './model/api-brand';
import deviceType from './model/api-deviceType';
import remoteControllers from './model/api-remoteControllers';
import district from './model/api-district';
import i18n from './model/api-i18n';
import template from './model/api-template';
import region from './model/api-region';
import property from './model/api-property';
import loadedDeviceType from './model/api-loadedDeviceTypes';
import file from './model/api-file';
import productCategories from './model/api-productCategories';
import productClasses from './model/api-productClasses';
import product from './model/api-product';
import productSubClass from './model/api-productSubClasses';
import productConfiguration from './model/api-configuration';
import productService from './model/api-productService';
import language from './model/api-language';
import i18nEntity from './model/api-i18nEntity';
import entityLanguage from './model/api-entityLanguage';
import entry from './model/api-entry';
import holidays from './model/api-holidays';
import productMetaData from './model/api-productMetaData';
import cpuModel from './model/api-cpuModel';
import cpuFunction from './model/api-cpuFunction';
import cpuHardware from './model/api-cpuHardware';
import cpuSoftware from './model/api-cpuSoftware';
import cpuRelation from './model/api-cpuRelation';
import publicApi from './model/api-public';
import productModel from './model/api-productModel';
import IdapUser from './model/api-IdapUser';
import dynamicDepot from './model/api-dynamicDepot';
import scenceType from './model/api-scenceType';
import platform from './model/api-platform';
import role from './model/api-role';

export default {
  admin,
  user,
  serviceProvider,
  brand,
  deviceType,
  remoteControllers,
  district,
  i18n,
  template,
  region,
  property,
  loadedDeviceType,
  file,
  productCategories,
  productClasses,
  product,
  productSubClass,
  productConfiguration,
  productService,
  language,
  i18nEntity,
  entityLanguage,
  entry,
  holidays,
  productMetaData,
  cpuModel,
  cpuHardware,
  cpuSoftware,
  cpuFunction,
  cpuRelation,
  publicApi,
  productModel,
  IdapUser,
  dynamicDepot,
  scenceType,
  platform,
  role,
};
