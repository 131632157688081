<template>
  <a-modal
    title="角色管理"
    width="100%"
    :visible="visible"
    wrapClassName="role-full-modal"
    :footer="null"
    @cancel="closeModel"
  >
    <div class="header">
      <div class="filter-box">
        <a-input-search
          class="filter-input"
          v-model="searchParams.name"
          placeholder="请输入角色名称"
          allow-clear
          @search="doSearch"
        ></a-input-search>
        <a-button type="primary" @click="doSearch">查询</a-button>
      </div>
      <a-button v-if="$hasPermissions(['rcs:role:create'])" type="primary" @click="handleCreate">新增</a-button>
    </div>
    <div>
      <a-table
        :loading="tableLoading"
        :data-source="tableData"
        :pagination="false"
        tableLayout="fixed"
        row-key="id"
        size="middle"
      >
        <a-table-column align="center" title="角色编码" data-index="encode" key="encode"> </a-table-column>
        <a-table-column align="center" title="角色名称" data-index="name" key="name"> </a-table-column>
        <a-table-column align="center" title="角色备注" data-index="remark" key="remark">
          <template slot-scope="scope">
            <span>{{ scope ? scope : '-' }}</span>
          </template>
        </a-table-column>
        <a-table-column align="center" title="创建时间" data-index="createdTime" key="createdTime">
          <template slot-scope="scope">
            {{ scope | parseTime | orNA }}
          </template>
        </a-table-column>
        <a-table-column align="center" title="修改时间" data-index="lastModificationTime" key="lastModificationTime">
          <template slot-scope="scope">
            {{ scope | parseTime | orNA }}
          </template>
        </a-table-column>
        <a-table-column align="center" title="操作" v-if="$hasPermissions(['rcs:role:update', 'rcs:role:delete'])">
          <template slot-scope="scope">
            <template v-if="!scope.builtin">
              <a-button type="link" v-if="$hasPermissions(['rcs:role:update'])" @click="handleUpdate(scope)"
                >编辑</a-button
              >
              <a-button
                type="link"
                v-if="$hasPermissions(['rcs:role:update'])"
                @click="handleOpenPermissionDrawer(scope)"
              >
                权限管理
              </a-button>
              <a-popconfirm
                v-if="$hasPermissions(['rcs:role:delete'])"
                title="确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="handleDelete(scope)"
              >
                <a-button type="link" style="color: red">删除</a-button>
              </a-popconfirm>
            </template>
            <a-tag v-else color="geekblue">
              内置角色
            </a-tag>
          </template>
        </a-table-column>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </div>
    <RolePermissionDrawer ref="rolePermissionDrawer" />
    <RoleFormModal ref="roleFormModal" />
  </a-modal>
</template>

<script>
import RolePermissionDrawer from './components/RolePermissionDrawer';
import RoleFormModal from './components/RoleFormModal';

export default {
  name: 'RoleModal',
  components: { RolePermissionDrawer, RoleFormModal },
  data() {
    return {
      visible: false,
      tableLoading: false,
      tableData: [],
      searchParams: {
        name: undefined,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    closeModel() {
      this.visible = false;
      this.searchParams = {
        name: undefined,
      };
      this.pageInfo.page = 1;
      this.tableData = [];
    },
    showRoleModal() {
      this.listRoles();
      this.visible = true;
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    async listRoles() {
      this.tableLoading = true;
      const params = {
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
        ...this.searchParams,
      };
      try {
        const res = await this.$apiManager.role.list(params);
        this.tableData = res.data.elements;
        this.$set(this.pageInfo, 'totalElements', res.data.totalElements);
        this.$set(this.pageInfo, 'totalPages', res.data.totalPages);
        this.$set(this.pageInfo, 'currentPage', res.data.currentPage);
      } finally {
        this.tableLoading = false;
      }
    },
    async loadedPageData() {
      await this.listRoles();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listRoles();
      }
    },
    handleOpenPermissionDrawer(role) {
      this.$refs.rolePermissionDrawer.showPermissionDrawer(role, async (result) => {
        await this.$apiManager.role.update({
          fields: ['permissions'],
          data: result,
        });
        this.$message.success('修改成功');
        this.loadedPageData();
      });
    },
    async handleDelete(role) {
      await this.$apiManager.role.deleteById(role.id);
      this.$message.success('删除成功');
      this.loadedPageData();
    },
    handleCreate() {
      this.$refs.roleFormModal.showCreateModal(async (result) => {
        await this.$apiManager.role.create(result);
        this.$message.success('新增成功');
        this.loadedPageData();
      });
    },
    handleUpdate(role) {
      this.$refs.roleFormModal.showUpdateModal(role, async (result) => {
        await this.$apiManager.role.update({
          fields: ['name', 'remark'],
          data: result,
        });
        this.$message.success('修改成功');
        this.loadedPageData();
      });
    },
  },
};
</script>

<style lang="less">
.header {
  display: flex;
  justify-content: space-between;
  .filter-box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .filter-input {
      width: 200px;
      margin-right: 1rem;
    }
  }
}

.role-full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
