const FILTER_OPTION_PRODUCT_ID = {
  value: '0',
  label: '产品ID',
};
const FILTER_OPTION_PRODUCT_NAME = {
  value: '1',
  label: '产品名称',
};
const FILTER_OPTION_PRODUCT_DRIVER = {
  value: '2',
  label: '标识符',
};
const FILTER_OPTION_PRODUCT_PROTOCOL = {
  value: '3',
  label: '产品协议',
};
const FILTER_OPTION_PRODUCT_MODEL = {
  value: '4',
  label: '产品型号',
};
const FILTER_OPTIONS = [
  FILTER_OPTION_PRODUCT_ID,
  FILTER_OPTION_PRODUCT_NAME,
  FILTER_OPTION_PRODUCT_DRIVER,
  // FILTER_OPTION_PRODUCT_PROTOCOL,
  FILTER_OPTION_PRODUCT_MODEL,
];
const PRODUCT_PROTOCOL = {
  ZIG_BEE: 1,
  SMART_CONTROL_485: 2,
  LON: 3,
  WIFI: 4,
  NETWORK: 5,
  DMX512: 13,
  KNX: 23,
  SMART_CONTROL_RS485: 24,
  DALI_BUS: 25,
};
const ALL_PRODUCT_PROTOCOL = {
  1: 'ZIG_BEE',
  2: 'SMART_CONTROL_485',
  3: 'LON',
  4: 'WIFI',
  5: 'NETWORK',
  13: 'DMX512',
  23: 'KNX',
  24: 'SMART_CONTROL_RS485',
  25: 'DALI_BUS',
};
const PRODUCT_PROTOCOL_OPTIONIS = [
  {
    value: 1,
    label: 'ZIG_BEE',
  },
  {
    value: 2,
    label: 'SMART_CONTROL_485',
  },
  {
    value: 3,
    label: 'LON',
  },
  {
    value: 4,
    label: 'WIFI',
  },
  {
    value: 5,
    label: 'NETWORK',
  },
  {
    value: 13,
    label: 'DMX512',
  },
  {
    value: 23,
    label: 'KNX',
  },
  {
    value: 24,
    label: 'SMART_CONTROL_RS485',
  },
  {
    value: 25,
    label: 'DALI_BUS',
  },
];
const PRODUCT_TYPE = {
  1: '父设备',
  2: '子设备',
  3: '第三方设备',
};
const PRODUCT_TYPE_OPTIONS = {
  root: 1,
  child: 2,
  thirdParty: 3,
};
const columns = [
  {
    title: '产品ID',
    dataIndex: 'productId',
    key: 'productId',
    align: 'center',
  },
  {
    title: '产品名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '中控内部驱动标识符',
    dataIndex: 'driver',
    key: 'driver',
    align: 'center',
  },
  {
    title: '关联产品小类',
    dataIndex: 'productSubClass',
    key: 'productSubClass',
    scopedSlots: { customRender: 'productSubClass' },
    align: 'center',
  },
  {
    title: '子设备数量',
    dataIndex: 'childrenCount',
    key: 'childrenCount',
    align: 'center',
  },
  {
    title: '产品控制协议类型',
    dataIndex: 'protocol',
    key: 'protocol',
    scopedSlots: { customRender: 'protocol' },
    align: 'center',
  },
  {
    title: '用户是否可见',
    dataIndex: 'userVisible',
    key: 'userVisible',
    scopedSlots: { customRender: 'userVisible' },
    align: 'center',
  },
  {
    title: '发布区域',
    dataIndex: 'regions',
    key: 'regions',
    scopedSlots: { customRender: 'regions' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

const USE_FREQUENCY_OPTIONS = [
  {
    value: 1,
    label: '正常',
  },
  {
    value: 2,
    label: '弃用',
  },
  {
    value: 3,
    label: '不常用',
  },
  {
    value: 4,
    label: '不显示',
  },
];

const DATA_PRIMITIVE_TYPES = {
  INT32: 0,
  FLOAT: 1,
  BOOL: 2,
  STRING: 3,
  RGB_STRING: 4,
  STRING_ARRAY: 5,
  TIME_VALUE: 6,
  LONG: 7,
  RGB_STRING_ARRAY: 8,
  FILE_TYPE: 9,
};
export default {
  FILTER_OPTION_PRODUCT_ID,
  FILTER_OPTION_PRODUCT_NAME,
  FILTER_OPTIONS,
  FILTER_OPTION_PRODUCT_PROTOCOL,
  columns,
  PRODUCT_PROTOCOL,
  ALL_PRODUCT_PROTOCOL,
  PRODUCT_PROTOCOL_OPTIONIS,
  PRODUCT_TYPE,
  PRODUCT_TYPE_OPTIONS,
  FILTER_OPTION_PRODUCT_DRIVER,
  USE_FREQUENCY_OPTIONS,
  FILTER_OPTION_PRODUCT_MODEL,
  DATA_PRIMITIVE_TYPES,
};
