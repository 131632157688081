const FILTER_OPTION_PRODUCT_CLASS_ID = {
  value: '0',
  label: '产品ID',
};
const FILTER_OPTION_PRODUCT_CLASS_NAME = {
  value: '1',
  label: '产品名称',
};
const FILTER_OPTIONS = [FILTER_OPTION_PRODUCT_CLASS_ID, FILTER_OPTION_PRODUCT_CLASS_NAME];
const columns = [
  {
    title: '产品大类名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '所属产品目录名称',
    dataIndex: 'productCategoryName',
    key: 'productCategoryName',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' },
    align: 'center',
  },
  {
    title: '上次修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  FILTER_OPTION_PRODUCT_CLASS_ID,
  FILTER_OPTION_PRODUCT_CLASS_NAME,
  FILTER_OPTIONS,
  columns,
};
