import http from '../http-engine';

export default {
  getBrands(params) {
    return http.get('/api/v1/brands/', { params });
  },
  createBrands(params) {
    return http.post('/api/v1/brands/', params);
  },
  updateBrands(params) {
    return http.put('/api/v1/brands/', params);
  },
  deleteBrands(params) {
    return http.delete(`/api/v1/brands/${params}`);
  },
  searchBrands(params) {
    return http.get(`/api/v1/brandRemoteRels/${params}/brand/`);
  },
};
