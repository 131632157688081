import http from '../http-engine';

export default {
  getCpuModelList(payload) {
    return http.get('/api/v1/cpuModels', { params: payload });
  },
  addCpuModel(payload) {
    return http.post('/api/v1/cpuModels', payload);
  },
  getCpuModel(model) {
    return http.get(`/api/v1/cpuModels/${model}`);
  },
  delCpuModel(model) {
    return http.delete(`/api/v1/cpuModels/${model}`);
  },
  updateCpuModel(payload) {
    return http.patch(`/api/v1/cpuModels/${payload.data.model}`, payload);
  },
};
