const FILTER_OPTION_LOADED_DEVICE_ID = {
  value: '0',
  label: '负载类型ID',
};
const FILTER_OPTION_LOADED_DEVICE_NAME = {
  value: '1',
  label: '负载类型名称',
};
const FILTER_OPTIONS = [FILTER_OPTION_LOADED_DEVICE_NAME, FILTER_OPTION_LOADED_DEVICE_ID];

const CATEGORY_LIGHT = {
  value: 1,
  label: '灯光',
};
const CATEGORY_CURTAIN = {
  value: 2,
  label: '窗帘',
};
const SENSOR = {
  value: 3,
  label: '传感器',
};
const CATEGORY_OPTION = [CATEGORY_LIGHT, CATEGORY_CURTAIN, SENSOR];

const columns = [
  {
    title: '负载类型ID',
    dataIndex: 'loadedDeviceTypeId',
    key: 'loadedDeviceTypeId',
    align: 'center',
  },
  {
    title: '负载类型名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '发布区域',
    dataIndex: 'regions',
    key: 'regions',
    scopedSlots: { customRender: 'regions' },
    align: 'center',
    width: '300px',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' },
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

const ImageTypes = {
  // 未知
  UNSPECIFIED: 0,
  // AIHOME/NEXSMART
  AIHOME: 1,
  // 智慧空间
  SMARTSPACE: 2,
  // NEXSMART
  NEXSMART: 3,
};
const ImageTypeLabel = ['未知', 'AIHOME', '智慧空间', 'NEXSMART'];

const Categories = {
  // 未知
  UNSPECIFIED: 0,
  // 灯光
  LIGHT: 1,
  // 窗帘
  CURTAIN: 2,
  // 传感器
  SENSOR: 3,
};
const CategoriesLabel = {
  0: '未知',
  1: '灯光',
  2: '窗帘',
  3: '传感器',
};
export default {
  FILTER_OPTION_LOADED_DEVICE_ID,
  FILTER_OPTION_LOADED_DEVICE_NAME,
  FILTER_OPTIONS,
  columns,
  ImageTypes,
  ImageTypeLabel,
  Categories,
  CategoriesLabel,
  CATEGORY_OPTION,
};
