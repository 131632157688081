import sideBar from './side-bar';
import event from './event';
import user from './user';
import remoteController from './remote-controller';
import serviceProvider from './serviceProvider';
import brand from './brand';
import deviceType from './deviceType';
import region from './region';
import productCategory from './product-category';
import property from './property';
import productClass from './product-class';
import product from './product';
import loadedDevice from './loaded-device';
import productSubClass from './product-sub-class';
import file from './file';
import productConfiguration from './product-configuration';
import productService from './product-service';
import language from './language';
import i18nEntity from './i18n-entity';
import entry from './entry';
import holidays from './holidays';
import cpuModel from './cpu-model';
import cpuHardware from './cpu-hardware';
import cpuSoftware from './cpu-software';
import cpuFunction from './cpu-function';
import cpuRelation from './cpu-relation';
import productFormItem from './product-form-item';
import productModel from './product-model';
import IdapUser from './Idap-user';
import scenceType from './scence-type';
import platform from './platform';

export default {
  event,
  user,
  sideBar,
  remoteController,
  serviceProvider,
  brand,
  deviceType,
  region,
  productCategory,
  property,
  productClass,
  product,
  loadedDevice,
  productSubClass,
  file,
  productConfiguration,
  productService,
  language,
  i18nEntity,
  entry,
  holidays,
  cpuModel,
  cpuHardware,
  cpuSoftware,
  cpuFunction,
  cpuRelation,
  productFormItem,
  productModel,
  IdapUser,
  scenceType,
  platform,
};
