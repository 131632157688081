import http from '../http-engine';

export default {
  getCpuHardwareList(payload) {
    return http.get(`/api/v1/cpuModels/${payload.model}/hardVersions`, { params: payload });
  },
  addCpuHardware(payload) {
    return http.post(`/api/v1/cpuModels/${payload.model}/hardVersions`, payload);
  },
  getCpuHardware(payload) {
    return http.get(`/api/v1/cpuModels/${payload.model}/hardVersions/${payload.hardVersion}`);
  },
  delCpuHardware(payload) {
    return http.delete(`/api/v1/cpuModels/${payload.model}/hardVersions/${payload.hardVersion}`);
  },
  updateCpuHardware(payload) {
    return http.patch(`/api/v1/cpuModels/${payload.data.model}/hardVersions/${payload.data.hardVersion}`, payload);
  },
};
