const columns = [
  {
    title: '国际化实体编号',
    dataIndex: 'entity',
    key: 'entity',
    align: 'center',
  },
  {
    title: '国际化实体名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' },
    align: 'center',
  },
  {
    title: '上次修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  columns,
};
