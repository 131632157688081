import http from '../http-engine';

export default {
  getRegions(params) {
    return http.get('/api/v1/regions', { params });
  },
  createRegions(params) {
    return http.post('/api/v1/regions/', params);
  },
  updateRegions(params) {
    return http.patch(`/api/v1/regions/${params.data.identifier}`, params);
  },
  deleteRegions(params) {
    return http.delete(`/api/v1/regions/${params}`);
  },
  refreshImageType(identifier) {
    return http.post('/api/v1/rcsFunction/imageTypeIds:refresh', {identifier});
  },
};
