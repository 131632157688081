import http from '../http-engine';

export default {
  getTemplates(params) {
    return http.get(`/api/v1/templates/${params}`);
  },
  updateTemplates(params) {
    return http.put('/api/v1/templates/', params);
  },
};
