import http from '../http-engine';

export default {
  getDeviceTypes(params) {
    return http.get('/api/v1/deviceTypes/', { params });
  },
  createDeviceTypes(params) {
    return http.post('/api/v1/deviceTypes/', params);
  },
  updateDeviceTypes(params) {
    return http.put('/api/v1/deviceTypes/', params);
  },
  deleteDeviceTypes(params) {
    return http.delete(`/api/v1/deviceTypes/${params}`);
  },
  loadedDeviceType(params) {
    return http.get('/api/v1/loadedDeviceTypes', { params });
  },
  infraredtemplates(params) {
    return http.get(`/api/v1/templates/${params}`);
  },
};
