const columns = [
  {
    title: '中控型号',
    dataIndex: 'model',
    key: 'model',
    align: 'center',
    sorter: true,
  },
  {
    title: '中控软件版本',
    dataIndex: 'softVersion',
    key: 'softVersion',
    align: 'center',
    sorter: true,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
  },
  {
    title: '最后修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    align: 'center',
    sorter: true,
    width: '15%',
    scopedSlots: { customRender: 'lastModificationTime' },
  },
  {
    title: '操作',
    key: 'action',
    width: '25%',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
];
const selectOption = [
  {
    value: 'softVersion',
    label: '中控软件版本',
  },
  {
    value: 'remark',
    label: '备注',
  },
];
const optionEnum = {
  SOFTVERSION: 'softVersion',
  REMARK: 'remark',
};
export default {
  columns,
  selectOption,
  optionEnum,
};
