const columns = [
  {
    title: '词条中文',
    dataIndex: 'curCN',
    key: 'curCN',
    align: 'center',
  },
  {
    title: '实体名称',
    dataIndex: 'entityName',
    key: 'entityName',
    align: 'center',
  },
  {
    title: '语言编号',
    dataIndex: 'languageId',
    key: 'languageId',
    align: 'center',
  },
  {
    title: '内容',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
  },
  //   {
  //     title: 'key',
  //     dataIndex: 'key',
  //     key: 'key',
  //     width: '25%',
  //     align: 'center',
  //   },
  {
    title: '词条上下文',
    dataIndex: 'context',
    key: 'context',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const status = {
  enable: 1,
  disabled: 2,
};
export default {
  columns,
  status,
};
