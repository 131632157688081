import http from '../http-engine';

export default {
  getEntries(entityId, params) {
    return http.get(`/api/v1/entryI18n/${entityId}/entries`, { params });
  },
  updateEntry(params) {
    return http.post('/api/v1/entryI18n:updateEntry', params);
  },
  updateEntries(params) {
    return http.post('/api/v1/entryI18n:batchUpdateEntry', params);
  },
  updateEntryStatus(params) {
    return http.post('/api/v1/entryI18n:updateEntryStatus', params);
  },
  refreshEntry() {
    return http.get('/api/v1/entryI18n:refreshEntry');
  },
  getKeyInfomation(params) {
    return http.get('/api/v1/entryI18n:keyInformation', { params });
  },
};
