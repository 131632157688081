import http from '../http-engine';

export default {
  getPlatforms(params) {
    return http.get('/api/v1/platforms', { params });
  },
  getPlatformsById(id) {
    return http.get(`/api/v1/platforms/${id}`);
  },
  createPlatform(params) {
    return http.post('/api/v1/platforms', params);
  },
  updatePlatform(params) {
    return http.patch(`/api/v1/platforms/${params.id}`, {platformDto: params.payload});
  },
  deletePlatform(id) {
    return http.delete(`/api/v1/platforms/${id}`);
  },
  refreshPlatform(id) {
    return http.post(`/api/v1/platforms/${id}/refresh`, {});
  },
  getSecret(id) {
    return http.get(`/api/v1/platforms/${id}/secret`);
  },
};
