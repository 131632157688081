import http from '../http-engine';

export default {
  getLoadedDeviceTypes(params) {
    return http.get('/api/v1/loadedDeviceTypes', { params });
  },
  getLoadedDeviceTypeById(params, headers) {
    return http.get(`/api/v1/loadedDeviceTypes/${params.loadedDeviceTypeId}`, { headers });
  },
  createLoadedDeviceType(params) {
    return http.post('/api/v1/loadedDeviceTypes', params);
  },
  updateLoadedDeviceType(params) {
    const param = {
      fields: ['name', 'detailPage', 'images', 'propertyIds', 'regionIdentifiers', 'categories'],
      data: params,
    };
    return http.patch(`/api/v1/loadedDeviceTypes/${params.loadedDeviceTypeId}`, param);
  },
  deleteLoadedDeviceType(params) {
    return http.delete(`/api/v1/loadedDeviceTypes/${params}`);
  },
};
