import http from '../http-engine';

export default {
  getCpuRelationList(payload) {
    return http.get(`/api/v1/cpuModels/${payload.model}/featureRelations`, { params: payload });
  },
  addCpuRelation(payload) {
    return http.post(`/api/v1/cpuModels/${payload.model}/featureRelations`, payload);
  },
  getCpuRelation(payload) {
    return http.get(`/api/v1/cpuModels/${payload.model}/featureRelations:getByVersion`, { params: payload });
  },
  delCpuRelation(id) {
    return http.delete(`/api/v1/cpuModels/-/featureRelations/${id}`);
  },
  updateCpuRelation(payload) {
    return http.put(`/api/v1/cpuModels/${payload.model}/featureRelations`, payload, {
      params: { hardVersion: payload.hardVersion, softVersion: payload.softVersion },
    });
  },
};
