import http from '../http-engine';

export default {
  getProperties(params) {
    return http.get('/api/v1/properties', { params });
  },
  getPropertiesById(params, headers) {
    return http.get(`/api/v1/properties/${params.id}:batchGet`, { headers });
  },
  createProperty(params) {
    return http.post('/api/v1/properties', params);
  },
  updateProperty(params) {
    const param = {
      fields: ['name', 'description', 'type', 'accessMode', 'encode'],
      data: params,
    };
    return http.patch(`/api/v1/properties/${params.id}`, param);
  },
  deleteProperty(params) {
    return http.delete(`/api/v1/properties/${params}`);
  },
};
