const FILTER_OPTION_REMOTE_ID = {
  value: '0',
  label: '码库id',
};
const FILTER_OPTION_REMOTE_NAME = {
  value: '1',
  label: '码库名称',
};
const FILTER_OPTION_REMOTE_BRAND = {
  value: '0',
  label: '品牌',
};
const FILTER_OPTION_REMOTE_PROVIDER = {
  value: '1',
  label: '服务商',
};
const countryColumns = [
  {
    width: '30%',
    title: '语言',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
  },
  {
    title: '码库名称',
    dataIndex: 'nameMessage',
    scopedSlots: { customRender: 'nameMessage' },
    ellipsis: true,
  },
  {
    title: '备注',
    dataIndex: 'remarkMessage',
    scopedSlots: { customRender: 'remarkMessage' },
    ellipsis: true,
  },
  {
    width: '20%',
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const modeColumns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: '20%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '温度',
    dataIndex: 'temperature',
    scopedSlots: { customRender: 'temperature' },
    ellipsis: true,
  },
  {
    title: '速度',
    dataIndex: 'speed',
    scopedSlots: { customRender: 'speed' },
    ellipsis: true,
  },
  {
    width: '20%',
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const extsColumns = [
  {
    title: '标签',
    dataIndex: 'tag',
    width: '10%',
    scopedSlots: { customRender: 'tag' },
  },
  {
    title: '值',
    dataIndex: 'value',
    scopedSlots: { customRender: 'value' },
    ellipsis: true,
  },
  {
    width: '20%',
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: '20%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '时间码',
    dataIndex: 'pulse',
    scopedSlots: { customRender: 'pulse' },
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '20%',
    scopedSlots: { customRender: 'operation' },
  },
];
export default {
  FILTER_OPTION_REMOTE_ID,
  FILTER_OPTION_REMOTE_NAME,
  FILTER_OPTION_REMOTE_BRAND,
  FILTER_OPTION_REMOTE_PROVIDER,
  modeColumns,
  extsColumns,
  columns,
  countryColumns,
};
