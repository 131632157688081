import http from '../http-engine';

export default {
  getSupport(params) {
    return http.get('/api/v1/i18n/support', { params });
  },
  listI18n(entityName, relationId) {
    return http.get(`/api/v1/i18n/${entityName}/${relationId}`);
  },
  editI18n(entityName, params) {
    return http.post(`/api/v1/i18n/${entityName}`, params);
  },
};
