const FILTER_OPTION_PROVIDER_NAME = {
  value: '0',
  label: '服务商名称',
};
const countryColumns = [
  {
    width: '30%',
    title: '语言',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
  },
  {
    title: '服务商名称',
    dataIndex: 'nameMessage',
    scopedSlots: { customRender: 'nameMessage' },
    ellipsis: true,
  },
  {
    width: '20%',
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const columns = [
  {
    title: '服务商id',
    dataIndex: 'serviceProviderId',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' },
    align: 'center',
  },
  {
    title: '服务商名称',
    dataIndex: 'name',
    key: 'providerName',
    align: 'center',
  },
  // {
  //   title: '备注说明',
  //   dataIndex: 'remark',
  //   key: 'remark',
  //   align: 'center',
  // },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  FILTER_OPTION_PROVIDER_NAME,
  columns,
  countryColumns,
};
