const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: '场景类型名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'creatorView',
    key: 'creatorView',
    align: 'center',
    scopedSlots: { customRender: 'creatorView' },
  },
  {
    title: '发布区域',
    dataIndex: 'regions',
    key: 'regions',
    scopedSlots: { customRender: 'regions' },
    align: 'center',
    width: '300px',
  },
  {
    title: '平台',
    dataIndex: 'supportPlatforms',
    key: 'supportPlatforms',
    scopedSlots: { customRender: 'supportPlatforms' },
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    align: 'center',
    scopedSlots: { customRender: 'createdTime' },
  },
  {
    title: '上次修改时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '状态',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const status = {
  enable: 1,
  disable: 2,
};

const supportPlatforms = {
  SMART_SPACE: 'SMART_SPACE',
};

const supportPlatformNames = {
  SMART_SPACE: '智慧空间',
};

export default {
  columns,
  status,
  supportPlatforms,
  supportPlatformNames,
};
