import Vue from 'vue';
import Antd, { FormModel } from 'ant-design-vue';
import XLSX from 'xlsx';
import vueXlsxTable from 'vue-xlsx-table';
import UUID from 'vue-uuid';
import filter from './common/filter';
import App from './App';
import router from './router';
import VeeValidate from './core/validate';
import store from './core/store';
import constRepository from './data/const/const-repository';
import apiManager from './api/api-manager';
import hasPermissions from './core/permission';
import 'ant-design-vue/dist/antd.css';

// require('./api/mockdata/mock-core');

Object.keys(filter).forEach((key) => {
  Vue.filter(key, filter[key]);
});
Vue.use(vueXlsxTable, { rABS: true });
Vue.use(UUID);
Vue.use(XLSX);
Vue.use(FormModel);
Vue.config.productionTip = false;
Vue.prototype.$constRepository = constRepository;
Vue.prototype.$apiManager = apiManager;
Vue.prototype.$hasPermissions = hasPermissions;
Vue.use(Antd);
Vue.use(VeeValidate, {
  errorBagName: 'veeErrors',
  fieldsBagName: 'veeFields',
  delay: 100,
  strict: true,
  enableAutoClasses: true,
  events: 'blur',
  inject: true,
});
export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
