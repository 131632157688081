<template>
  <div>
    <a-modal
      :footer="null"
      title="用户管理"
      :visible="visible"
      width="100%"
      wrapClassName="user-full-modal"
      @cancel="closeModel"
    >
      <div class="filter-box">
        <div class="filter-radio-select">
          <span @click="handleRadioChange(true)" ref="hasRoleSpan" class="radio-button selected-radio">有角色</span>
          <span @click="handleRadioChange(false)" ref="noRoleSpan" class="radio-button">全部</span>
          <span ref="glider" class="glider trans-zero"></span>
        </div>
        <a-select
          allow-clear
          @change="doSearch"
          class="filter-select"
          v-model="searchParams.roleId"
          show-search
          optionFilterProp="label"
          placeholder="请选择角色"
        >
        <a-select-option v-for="role in roleList" :key="role.id" :value="role.id" :label="role.name">
        {{ role.name }}
        </a-select-option>
        </a-select>
        <a-input-search
          class="filter-input"
          v-model="searchParams.searchKey"
          placeholder="邮箱、工号或姓名"
          allow-clear
          @search="doSearch"
        ></a-input-search>
        <a-button type="primary" @click="doSearch">查询</a-button>
      </div>
      <div>
        <a-table
          :loading="tableLoading"
          :data-source="tableData"
          :pagination="false"
          tableLayout="fixed"
          row-key="openId"
          size="middle"
        >
          <a-table-column align="center" title="工号" data-index="employeeNumber" key="employeeNumber"></a-table-column>
          <a-table-column align="center" title="姓名" data-index="realName" key="realName"></a-table-column>
          <a-table-column align="center" title="角色" data-index="roleNames" key="roleNames">
            <template slot-scope="scope">
              <span v-if="scope">
                {{ scope.join(',') }}
              </span>
              <span v-else>-</span>
            </template>
          </a-table-column>
          <a-table-column align="center" title="电话" data-index="phone" key="phone"></a-table-column>
          <a-table-column align="center" title="邮箱" data-index="mail" key="mail"></a-table-column>
          <a-table-column align="center" title="操作" v-if="$hasPermissions(['rcs:ldap_user:update'])">
            <template slot-scope="scope">
              <a-button type="link" @click="handleEditRole(scope)"> 编辑 </a-button>
            </template>
          </a-table-column>
        </a-table>
        <div class="pagination">
          <a-pagination
            :current="pageInfo.page"
            :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
            show-quick-jumper
            show-size-changer
            :total="pageInfo.totalElements"
            @showSizeChange="onShowSizeChange"
            @change="onChange"
            :pageSizeOptions="['10', '20', '30', '40']"
          />
          <br />
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="roleModalVisible"
      title="角色更改"
      @ok="handleUpdateUserRole"
      @cancel="handleCloseRoleModel"
      :confirmLoading="confirmLoading"
    >
      <a-form :form="currentUser">
        <a-form-item label="工号">
          <a-input disabled v-model="currentUser.employeeNumber"></a-input>
        </a-form-item>
        <a-form-item label="姓名">
          <a-input disabled v-model="currentUser.realName"></a-input>
        </a-form-item>
        <a-form-item label="角色" required>
          <a-select
            v-model="selectedRoles"
            mode="multiple"
            optionFilterProp="label"
            show-search
            @change="handleRoleChange"
          >
          <a-select-option v-for="role in roleList" :key="role.id" :value="String(role.id)" :label="role.name">
        {{ role.name }}
        </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'UserModal',
  data() {
    return {
      tableData: [],
      searchParams: {
        searchKey: undefined,
        roleId: undefined,
        filterRole: true,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRoles: [],
      roleModalVisible: false,
      currentUser: {},
      visible: false,
      tableLoading: false,
      confirmLoading: false,
      roleList: [],
    };
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    async listUsers() {
      this.tableLoading = true;
      const params = {
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
        ...this.searchParams,
      };
      try {
        const res = await this.$apiManager.IdapUser.getUsers(params);
        this.tableData = res.data.elements;
        this.$set(this.pageInfo, 'totalElements', res.data.totalElements);
        this.$set(this.pageInfo, 'totalPages', res.data.totalPages);
        this.$set(this.pageInfo, 'currentPage', res.data.currentPage);
      } finally {
        this.tableLoading = false;
      }
    },
    async loadedPageData() {
      await this.listUsers();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listUsers();
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    handleUpdateUserRole() {
      if (!this.currentUser.roleId) {
        this.$message.info('请选择角色');
        return;
      }
      this.confirmLoading = true;
      this.$apiManager.IdapUser.updateUserRole({
        openId: this.currentUser.openId,
        role: this.currentUser.roleId,
      })
        .then(() => {
          this.$message.success('修改成功');
          this.roleModalVisible = false;
          this.loadedPageData();
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    handleEditRole(user) {
      this.currentUser = lodash.cloneDeep(user);
      this.selectedRoles = user.roleId ? user.roleId.split(',') : [];
      this.roleModalVisible = true;
    },
    handleCloseRoleModel() {
      this.roleModalVisible = false;
      this.currentUser = {};
      this.selectedRoles = [];
    },
    async fetchRoleList() {
      const res = await this.$apiManager.role.list({ unpaged: true });
      this.roleList = res.data.elements;
    },
    openModel() {
      this.loadedPageData();
      this.fetchRoleList();
      this.visible = true;
    },
    closeModel() {
      this.visible = false;
      this.searchParams = {
        searchKey: undefined,
        roleId: undefined,
        filterRole: true,
      };
      this.pageInfo.page = 1;
      this.clearClass();
      this.tableData = [];
    },
    clearClass() {
      this.$refs.hasRoleSpan.classList.remove('selected-radio');
      this.$refs.noRoleSpan.classList.remove('selected-radio');
      this.$refs.glider.classList.remove('trans-zero');
      this.$refs.glider.classList.remove('trans-hundred');
    },
    handleRadioChange(flag) {
      this.searchParams.filterRole = flag;
      this.doSearch();
    },
    handleRoleChange(roles) {
      if (roles && roles.length) {
        this.currentUser.roleId = roles.join(',');
      } else {
        this.currentUser.roleId = undefined;
      }
    },
  },
  watch: {
    'searchParams.filterRole': {
      handler(newValue) {
        this.clearClass();
        if (newValue) {
          this.$refs.hasRoleSpan.classList.add('selected-radio');
          this.$refs.glider.classList.add('trans-zero');
        } else {
          this.$refs.noRoleSpan.classList.add('selected-radio');
          this.$refs.glider.classList.add('trans-hundred');
        }
      },
    },
  },
};
</script>

<style lang="less">
.user-full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
    overflow-y: auto;
  }
  @radio-height: 20px;
  @radio-width: 50px;
  .filter-box {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
    .filter-select {
      width: 200px;
      margin-right: 1%;
    }
    .filter-input {
      width: 200px;
      margin-right: 1%;
    }
    .filter-radio-select {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      margin-right: 1%;
      background-color: #fff;
      box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
      border-radius: 99px;
      padding: 0.4rem;
      background-color: #fff;
      .radio-button {
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        height: @radio-height;
        width: @radio-width;
        font-size: 0.8rem;
        color: black;
        font-weight: 500;
        border-radius: 99px;
        cursor: pointer;
        transition: color 0.15s ease-in;
      }
      .selected-radio {
        color: #185ee0;
      }
      .glider {
        position: absolute;
        left: 10px;
        display: flex;
        width: @radio-width;
        height: @radio-height;
        background-color: #e6eef9;
        z-index: 1;
        border-radius: 99px;
        transition: 0.25s ease-out;
      }
      .trans-zero {
        transform: translateX(-4%);
      }
      .trans-hundred {
        transform: translateX(120%);
        width: 45px;
      }
    }
  }
}
.pagination {
  margin-top: 1em;
}
</style>
