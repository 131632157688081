<template>
  <div>
    <a-menu
      :openKeys="openKeys"
      :selectedKeys="[$route.name]"
      mode="inline"
      theme="dark"
      @click="onRouteSelected"
      @openChange="onOpenChange"
    >
      <template v-for="item in this.$store.state.subsystemRouters">
        <a-menu-item v-if="!item.children && $hasPermissions(menuInfo.meta.permissionCodeList)" :key="item.name">
          <!-- <a-icon type="pie-chart" /> -->
          <span>{{ item.title }}</span>
        </a-menu-item>
        <sub-menu v-else :key="item.name" :menu-info="item" />
      </template>
    </a-menu>
  </div>
</template>

<script>
import { Menu } from 'ant-design-vue';
// import lodash from 'lodash';
// import navRouters from '../../router/nav-router';

const SubMenu = {
  template: `
    <div v-if="$hasPermissions(menuInfo.meta.permissionCodeList)">
      <a-sub-menu :key="menuInfo.name" v-bind="$props" v-on="$listeners">
        <span slot="title">
      <span>{{ menuInfo.meta.title }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children && item.meta.navBarVisible && $hasPermissions(item.meta.permissionCodeList)" :key="item.name" >
            <span>{{ item.meta.title  }}</span>
          </a-menu-item>
          <sub-menu v-else-if="item.meta.navBarVisible" :key="item.name" :menu-info="item" />
        </template>
      </a-sub-menu>
    </div>
    `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
export default {
  components: {
    'sub-menu': SubMenu,
  },
  data() {
    return {
      routers: this.$store.state.subsystemRouters,
      openKeys: ['aiHome', 'irRemote'],
      rootSubmenuKeys: this.$store.state.subsystemRouters[0].children.map((route) => route.name),
    };
  },
  mounted() {
    this.openKeys.push(this.$route.matched[this.$route.matched.length - 1].parent.name);
  },
  methods: {
    onRouteSelected(route) {
      this.$router.push({ name: route.key });
    },
    isRouterVisible(route) {
      return (
        route.show && this.$store.state.currentUser && this.$store.getters.permissionInclude(route.meta.visitPermission)
      );
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find((key) => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey, 'aiHome', 'irRemote'] : [];
      }
    },
    updateOpenKeys(params) {
      if (!this.openKeys.includes(params.name)) {
        this.openKeys.push(params.name);
      }
    },
  },
};
</script>
