const RoleOptions = [
  // { value: '0', label: '未知' },
  { value: '1', label: '设备' },
  { value: '2', label: '应用' },
  { value: '3', label: '施工APP' },
  { value: '4', label: 'PAAS服务' },
  { value: '5', label: '产品管理员' },
  { value: '6', label: 'APP开发人员' },
  { value: '7', label: 'PAAS开发人员' },
  { value: '8', label: '网关开发人员' },
  { value: '9', label: '词条翻译人员' },
  { value: '10', label: '审核人员' },
  { value: '11', label: '普通用户' },
  { value: '12', label: '动态库文件操作人员' },
  { value: '1000', label: '管理员' },
];

export default {
  RoleOptions,
};
