import VeeValidate, { Validator } from 'vee-validate';

const dictionary = {
  en: {
    messages: {
      required: () => '* 该项为必填项',
    },
  },
};

Validator.localize(dictionary);

export default VeeValidate;
